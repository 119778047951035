import { cva } from 'class-variance-authority';
import React, { DetailedHTMLProps, forwardRef } from 'react';
import './Icon.css';

import { cn } from '@/lib/utils';

const iconVariants = cva('z-icon select-none', {
	variants: {
		size: {
			inherit: 'text-inherit',
			lg: 'text-lg',
			md: 'text-base',
			sm: 'text-sm',
			xs: 'text-xs',
		},
	},
	defaultVariants: {
		size: 'inherit',
	},
});

export type CustomIconProps = {
	unicode: string;
	size?: 'sm' | 'md' | 'lg' | 'xs' | 'inherit';
};

export type IconProps = CustomIconProps & DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
const Icon = forwardRef<HTMLElement, IconProps>(({ unicode, size = 'inherit', style, className, ...props }, ref) => {
	return (
		<i
			className={cn(iconVariants({ size }), className)}
			ref={ref}
			{...props}
			style={{
				...style,
				fontFamily: 'Wasabi',
				// @ts-expect-error
				'--unicode': `"\\${unicode?.toUpperCase()}"`,
			}}
		/>
	);
});

Icon.displayName = 'Icon';

export default Icon;
