import { LocateFixedIcon, MinusIcon, PlusIcon } from 'lucide-react';
import React from 'react';
import { useMap } from 'react-leaflet';

import { useAppStore } from '@/lib/store';
import { Button } from '@/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/ui/tooltip';
import { cn } from '@/lib/utils';
import { useTracker } from '@/lib/tracking/useTracker';

export function MapBottomControls() {
	const map = useMap();
	const setMapLiveLocationEnabled = useAppStore(state => state.setMapLiveLocationEnabled);
	const mapLiveLocationEnabled = useAppStore(state => state.mapLiveLocationEnabled);
	const { trackEvent } = useTracker();

	const zoomIn = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		map.setZoom(map.getZoom() + 1);
	};

	const zoomOutHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		map.setZoom(map.getZoom() - 1);
	};

	const isLiveLocationError = mapLiveLocationEnabled === 'disabled';

	return (
		<div className="absolute bottom-0 right-0 z-10 mb-10 mr-4 flex flex-row gap-4 md:mb-4">
			<div>
				<Button
					size="icon"
					variant="secondary"
					className="size-8 rounded-r-none border border-gray-300 shadow-2xl sm:size-10"
					onClick={zoomOutHandler}
					aria-label="Zoom Out"
				>
					<MinusIcon className="text-blue-600" />
				</Button>
				<Button
					size="icon"
					variant="secondary"
					className="size-8 rounded-l-none border border-l-0 border-gray-300 shadow-2xl sm:size-10"
					onClick={zoomIn}
					aria-label="Zoom In"
				>
					<PlusIcon className="text-blue-600" />
				</Button>
			</div>
			{/* <Tooltip> */}
			{/* <TooltipTrigger asChild> */}
			<Button
				disabled={isLiveLocationError}
				size="icon"
				variant="secondary"
				className={cn('size-8 border border-gray-300 shadow-2xl sm:size-10', {
					'cursor-not-allowed': isLiveLocationError,
					'animate-pulse': mapLiveLocationEnabled === 'loading',
				})}
				onClick={() => {
					setMapLiveLocationEnabled('loading');
					trackEvent({
						event_type: 'CLICK',
						component: 'LOCATE_ME',
						ui_source: 'MAP',
					});
				}}
				aria-label="Navigate to your location"
			>
				<LocateFixedIcon className="text-blue-700" />
			</Button>
			{/* </TooltipTrigger> */}
			{/* <TooltipContent side="left">
					<span className="text-sm">
						{mapLiveLocationEnabled === 'idle' && 'Locate Me'}
						{mapLiveLocationEnabled === 'error' && 'Please enable location services'}
						{mapLiveLocationEnabled === 'loading' && 'Loading'}
						{mapLiveLocationEnabled === 'success' && 'Live location enabled'}
						{mapLiveLocationEnabled === 'disabled' && 'Location services disabled'}
						{mapLiveLocationEnabled === 'not-found' && 'Location not found'}
					</span>
				</TooltipContent> */}
			{/* </Tooltip> */}
		</div>
	);
}
