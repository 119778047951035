import { RadioTowerIcon } from 'lucide-react';

import { Badge } from '@/ui/badge';
import { lexendFont } from '@/lib/utils';

import { METRIC_CONSTANTS } from '../utils';
import { getContrastYIQ, getMatchingStyles, IconBadgeProps, IDataSet } from './utils';
import { DownIcon } from './DownIcon';

const modeController = METRIC_CONSTANTS.temperature;
const TEMPERATURE_DATA_MODULARITY = 2;

const temperatureData: IDataSet[] = [
	{
		key: -6,
		style: { backgroundColor: '#27134E' },
	},
	{
		key: -4,
		style: { backgroundColor: '#361C6F' },
	},
	{
		key: -2,
		style: { backgroundColor: '#482889' },
	},
	{
		key: 0,
		style: { backgroundColor: '#082454' },
	},
	{
		key: 2,
		style: { backgroundColor: '#0E3272' },
	},
	{
		key: 4,
		style: { backgroundColor: '#1148A6' },
	},
	{
		key: 6,
		style: { backgroundColor: '#1155CB' },
	},
	{
		key: 8,
		style: { backgroundColor: '#256FEF' },
	},
	{
		key: 10,
		style: { backgroundColor: '#538CEE' },
	},
	{
		key: 12,
		style: { backgroundColor: '#9CC0FF' },
	},
	{
		key: 14,
		style: { backgroundColor: '#DBE8FF' },
	},
	{
		key: 16,
		style: { backgroundColor: '#F4FECD' },
	},
	{
		key: 18,
		style: { backgroundColor: '#F4FECD' },
	},
	{
		key: 18,
		style: { backgroundColor: '#E4F6A6' },
	},
	{
		key: 18,
		style: { backgroundColor: '#E4F6A6' },
	},
	{
		key: 20,
		style: { backgroundColor: '#D2EF7F' },
	},
	{
		key: 22,
		style: { backgroundColor: '#D2EF7F' },
	},
	{
		key: 24,
		style: { backgroundColor: '#BBD959' },
	},
	{
		key: 26,
		style: { backgroundColor: '#A0BF3A' },
	},
	{
		key: 28,
		style: { backgroundColor: '#F8D149' },
	},
	{
		key: 30,
		style: { backgroundColor: '#F3C117' },
	},
	{
		key: 32,
		style: { backgroundColor: '#E9B501' },
	},
	{
		key: 34,
		style: { backgroundColor: '#DC9D09' },
	},
	{
		key: 36,
		style: { backgroundColor: '#A16E17' },
	},
	{
		key: 38,
		style: { backgroundColor: '#ED8449' },
	},
	{
		key: 40,
		style: { backgroundColor: '#E86C37' },
	},
	{
		key: 42,
		style: { backgroundColor: '#CE501A' },
	},
	{
		key: 44,
		style: { backgroundColor: '#B53B12' },
	},
	{
		key: 46,
		style: { backgroundColor: '#802C10' },
	},
	{
		key: 48,
		style: { backgroundColor: '#661A00' },
	},
];

export function TemperatureIcon({ value, props, showUnavailable = false }: IconBadgeProps) {
	if (value === null) {
		return <DownIcon props={props} showUnavailable={showUnavailable} value={value} />;
	}

	const modeStyle = getMatchingStyles({ dataSet: temperatureData, value, modularity: TEMPERATURE_DATA_MODULARITY });

	return (
		<Badge
			className={`whitespace-nowrap rounded-full border border-white py-1 text-xs font-light text-white shadow-md animate-in slide-in-from-top-0 ${lexendFont.className}`}
			style={{
				...modeStyle,
				color: getContrastYIQ(modeStyle.backgroundColor as string),
			}}
			{...props}
		>
			<RadioTowerIcon size={16} className="mr-1" strokeWidth={1.5} />
			<span>
				{modeController.parser(value)}
				{modeController.unitPrefix}
				{modeController.unit}
			</span>
		</Badge>
	);
}
