import { useEffect, useRef, useState } from 'react';

export function useDelayedState(initialState: any, delay: number) {
	const [state, setState] = useState(initialState);
	const [isLoading, setIsLoading] = useState(true);
	const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		setIsLoading(true);
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		timeoutId.current = setTimeout(() => {
			setIsLoading(false);
		}, delay);

		return () => {
			if (timeoutId.current) clearTimeout(timeoutId.current);
		};
	}, [delay, initialState]);

	return { state, isLoading };
}
