import { cn } from '@/lib/utils';
import { Button } from '@/ui/button';
import { useWeatherModes } from '@/hooks/useWeatherModes';
import { useAppStore } from '@/lib/store';
import { useTracker } from '@/lib/tracking/useTracker';
import { WeatherModes } from '@/types/constants';

import { getMapControlData } from './utils';

export function MapSideControls() {
	const { trackEvent } = useTracker();
	const mapSelectedMode = useAppStore(state => state.mapSelectedMode);
	const setMapSelectedMode = useAppStore(state => state.setMapSelectedMode);
	const locationSelectedCity = useAppStore(state => state.locationSelectedCity);
	const locationSelectedLocality = useAppStore(state => state.locationSelectedLocality);
	const cityWeatherData = useAppStore(state => state.cityWeatherData);

	const excludedModes = [WeatherModes.RAIN_ACCUMULATION];

	const { AVAILABLE_WEATHER_MODES } = useWeatherModes({
		mapSelectedMode,
		setMapSelectedMode,
		excludedModes,
	});

	if (!cityWeatherData || !locationSelectedCity || !locationSelectedLocality) {
		return null;
	}

	const mapControlData = getMapControlData(cityWeatherData.data[locationSelectedLocality], excludedModes);

	if (AVAILABLE_WEATHER_MODES.length === 0) {
		/**
		 * DEV: This means that most probably deviceType is Unknown in this locality so at
		 * this moment user wont be shown live weather data. However, this case is only possible
		 * when default device gets unavailable for a locality. So now user can either manually
		 * select a drop pin from map
		 */
		console.warn('No available weather modes');
	}

	return (
		<div className="absolute top-10 z-20 max-w-full overflow-hidden sm:ml-4 sm:mt-4 md:left-6 md:top-0">
			<div className="no-scrollbar flex gap-2 overflow-x-scroll px-4 sm:flex-col sm:px-1 md:gap-2">
				{mapControlData.map(mode => {
					const isDisabled = !AVAILABLE_WEATHER_MODES.map(mode => mode.id).includes(mode.id);
					const btnClassName = cn(
						'capitalize backdrop-blur-sm justify-start rounded-full border border-white',
						{
							'bg-black/60 text-white hover:bg-black/70': mapSelectedMode !== mode.id,
						},
					);

					return (
						<Button
							variant="zomato"
							disabled={isDisabled}
							className={btnClassName}
							key={mode.id}
							onClick={() => {
								setMapSelectedMode(mode.id);
								trackEvent({
									event_type: 'CLICK',
									component: 'MAP_SIDE_CONTROL',
									ui_source: 'MAP_OVERLAY',
									meta_data: {
										button: mode.id,
									},
								});
							}}
						>
							<mode.icon className="mr-1 size-4 md:mr-2 md:size-5" />
							<span className="text-sm font-normal">{mode.label}</span>
						</Button>
					);
				})}
			</div>
		</div>
	);
}
