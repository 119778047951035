'use client';

import { ShareIcon } from 'lucide-react';
import { Popover, PopoverButton } from '@headlessui/react';
import Link from 'next/link';

import { Button } from '@/ui/button';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/ui/tooltip';
import { PopoverPanel } from '@/ui/popover';
import { Separator } from '@/ui/separator';
import { useAppStore } from '@/lib/store';
import { useToast } from '@/ui/use-toast';
import { ComponentTracker } from '@/lib/tracking/ComponentTracker';
import { APPLICATION_HOST, LANDING_PREFIX } from '@/lib/constants';
import { weatherEncodeLocation } from '@/lib/utils';

import Icon from './Icon/Icon';

const socials = [
	{
		label: 'Share on X',
		icon: <span className="size-5 rounded-sm bg-black text-center text-sm text-white">𝕏</span>,
		getLink: (postDescription: string, localityURL: string) =>
			`https://twitter.com/intent/tweet?text=${postDescription}&url=${localityURL}`,
	},
	{
		label: 'Share on Linkedin',
		icon: <Icon unicode={'E914'} className="size-5 rounded-sm bg-black text-center text-sm text-white" />,

		getLink: (postDescription: string, localityURL: string) =>
			`https://www.linkedin.com/shareArticle?mini=true&text=${postDescription}&url=${localityURL}`,
	},
];

export default function ShareToSocials() {
	const { toast } = useToast();

	const { selectedCityData, selectedLocalityData } = useAppStore(state => ({
		selectedCityData: state.selectedCityData,
		selectedLocalityData: state.selectedLocalityData,
	}));

	const localityURL = () => {
		const pageParams = weatherEncodeLocation({
			city: selectedCityData?.city_name || '',
			city_id: selectedCityData?.city_id || '',
			locality: selectedLocalityData?.locality_name || '',
			locality_id: selectedLocalityData?.locality_id || '',
		});

		return `${APPLICATION_HOST}/${LANDING_PREFIX}/${pageParams.city}/${pageParams.city_id}/${pageParams.locality}/${pageParams.locality_id}/`;
	};

	const postDescription = `Check live weather at ${selectedLocalityData?.locality_name},${selectedCityData?.city_name} on Weatherunion!`;

	const handleCopy = () => {
		navigator.clipboard.writeText(postDescription + ' ' + localityURL()).then(() => {
			toast({ variant: 'default', description: 'Copied to clipboard' });
		});
	};

	return (
		<Popover>
			<PopoverButton as={'div'}>
				<ShareButton />
			</PopoverButton>{' '}
			<PopoverPanel modal={true} anchor={{ to: 'bottom', gap: '4px' }} className="w-56" transition>
				{({ close }) => {
					return (
						<>
							{socials.map((social, index) => (
								<ComponentTracker
									key={index}
									event={{
										event_type: 'CLICK',
										ui_source: 'LANDING_PAGE',
										component: 'SHARE_TO_SOCIAL_LINK',
										meta_data: {
											city_id: selectedCityData?.city_id,
											locality_id: selectedLocalityData?.locality_id,
											locality_name: selectedLocalityData?.locality_name,
											city_name: selectedCityData?.city_name,
										},
									}}
								>
									<Link
										className="flex cursor-pointer items-center gap-4 px-4 py-2 hover:bg-accent"
										href={social.getLink(postDescription, localityURL())}
										referrerPolicy="no-referrer"
										target="_blank"
									>
										{social.icon}
										{social.label}
									</Link>
								</ComponentTracker>
							))}
							<Separator />
							<ComponentTracker
								event={{
									event_type: 'CLICK',
									ui_source: 'LANDING_PAGE',
									component: 'COPY_SHARE_POST',
									meta_data: {
										city_id: selectedCityData?.city_id,
										locality_id: selectedLocalityData?.locality_id,
										locality_name: selectedLocalityData?.locality_name,
										city_name: selectedCityData?.city_name,
									},
								}}
								onClick={() => {
									handleCopy();
									close();
								}}
							>
								<div className="flex cursor-pointer items-center gap-4 px-4 py-2 hover:bg-accent">
									<Icon
										unicode="E810"
										className="size-5 rounded-sm bg-black text-center text-sm text-white"
									/>
									Copy link
								</div>
							</ComponentTracker>
						</>
					);
				}}
			</PopoverPanel>
		</Popover>
	);
}

const ShareButton = () => {
	return (
		<Tooltip>
			<TooltipContent className="border-none bg-[#1c1c1c] text-white shadow-none">
				Share this to your community
				<TooltipArrow className="fill-[#1c1c1c] text-xl" />
			</TooltipContent>
			<TooltipTrigger asChild>
				<Button className="shadow-sm sm:shadow-md" variant="outline">
					<ShareIcon />
					<span className="sm:hidden">Share</span>
				</Button>
			</TooltipTrigger>
		</Tooltip>
	);
};
