import { Serie } from '@nivo/line';
import { CartesianMarkerProps } from '@nivo/core';

import { roundToNextMultiple, roundToPreviousMultiple } from '@/lib/round';
import { WeatherModes } from '@/types/constants';

import { METRIC_CONSTANTS } from '../WeatherMap/utils';

interface ParseGraphDataProps {
	data: GraphDataProps[];
	RANGE_GAP: number;
	filterMinZero?: boolean;
}

export function getRangeMinMax({
	data,
	filterMinZero,
	floor,
	ceil,
}: {
	data: GraphDataProps[];
	filterMinZero?: boolean;
	floor?: number;
	ceil?: number;
}) {
	let range = data.map(item => item.y).filter(item => item !== null && item !== undefined);

	if (filterMinZero) {
		range = range.filter(item => item !== 0); // Filter out 0 values
	}

	let minRange = floor ?? Math.min(...range) ?? 0;
	let maxRange = ceil ?? Math.max(...range) ?? 0;

	if (minRange === maxRange) {
		minRange = 0;
		maxRange = 1;
	}

	return {
		minRange,
		maxRange,
	};
}

export function parseGraphData({ data, RANGE_GAP, filterMinZero }: ParseGraphDataProps) {
	let range = data.map(item => item.y).filter(item => item !== null && item !== undefined);

	if (filterMinZero) {
		range = range.filter(item => item !== 0); // Filter out 0 values
	}

	let minRange = Math.min(...range) ?? 0;
	let maxRange = Math.max(...range) ?? 0;

	if (minRange === maxRange) {
		minRange = 0;
		maxRange = 1;
	}

	return {
		min: roundToPreviousMultiple(RANGE_GAP)(minRange),
		max: roundToNextMultiple(RANGE_GAP)(maxRange),
	};
}

interface GetTrendsDataProps {
	data: HourlyTrendsData[];
	mode: WeatherModes;
	preParse?: (value: number) => string | null;
}

export function getTrendsData({ data, mode, preParse }: GetTrendsDataProps): GraphDataProps[] {
	const parsedData = data.map(item => {
		const fillValue = item.locality_weather_trends_data[mode];

		if (fillValue === null || fillValue === undefined) {
			return {
				x: item.hour,
				y: 0,
			};
		}

		const value = METRIC_CONSTANTS[mode as WeatherModes].parser(fillValue);

		if (value === null) {
			return {
				x: item.hour,
				y: 0,
			};
		}

		return {
			x: item.hour,
			y: Number(fillValue),
		};
	});

	const preParsedData = parsedData.map(item => ({
		x: item.x,
		y: Number(preParse ? preParse(Number(item.y)) : item.y),
	}));

	return preParsedData;
}

export const BAR_GRAPH_COLOR = '#414A5D';

export function isTrendDowntime(data: HourlyTrendsData[], mode: WeatherModes) {
	const values = data.map(item => item.locality_weather_trends_data[mode]);

	if (mode === WeatherModes.TEMPERATURE) {
		return values.every(value => value === null || value === 0);
	} else {
		return values.every(value => value === null);
	}
}

export function getChartMarkers(data: GraphDataProps[]): CartesianMarkerProps[] {
	const initialX = data[0].x;
	const finalX = data[data.length - 1].x;

	if (!initialX || !finalX) {
		return [];
	}

	return [
		{
			axis: 'x',
			value: initialX,
			lineStyle: { stroke: '#e5e7eb', strokeWidth: 1 },
		},
		{
			axis: 'x',
			value: finalX,
			lineStyle: { stroke: '#e5e7eb', strokeWidth: 1 },
		},
	];
}
