import React from 'react';

import { BadgeProps } from '@/ui/badge';

export interface IDataSet {
	key: number;
	style: React.CSSProperties;
}

export interface IconBadgeProps {
	value: number | null;
	props?: BadgeProps;
	showUnavailable?: boolean;
}

interface GetMatchingStylesProps {
	dataSet: IDataSet[];
	value: number;
	modularity?: number;
}

export function getMatchingStyles({ dataSet, value, modularity = 1 }: GetMatchingStylesProps) {
	const matchingDataSet = dataSet.find(data => value >= data.key && value < data.key + modularity);

	if (!matchingDataSet) {
		return dataSet[dataSet.length - 1].style;
	}

	return matchingDataSet.style;
}

export function getContrastYIQ(hexcolor: string) {
	const r = parseInt(hexcolor.substring(1, 3), 16);
	const g = parseInt(hexcolor.substring(3, 5), 16);
	const b = parseInt(hexcolor.substring(5, 7), 16);
	const yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq >= 128 ? '#000000' : '#ffffff';
}
