import { Badge } from '@/ui/badge';

import { IconBadgeProps } from './utils';

export function DownIcon({ props, showUnavailable, value }: IconBadgeProps) {
	if (showUnavailable) {
		return (
			<Badge
				className="whitespace-nowrap rounded-full border border-white bg-gray-800 py-1 text-xs font-normal text-white shadow-md hover:bg-gray-700"
				{...props}
			>
				{value || 'NA'}
			</Badge>
		);
	} else {
		return null;
	}
}
