import { Datum, Serie } from '@nivo/line';

import { generateRange } from '@/lib/round';

import { LineChart } from './Charts/Line';
import YAxis from './YAxis';
import { METRIC_CONSTANTS } from '../WeatherMap/utils';
import { getChartMarkers, getRangeMinMax, parseGraphData } from './utils';
import { GraphTooltip } from './GraphTooltip';

export function TemperatureChart({ data }: TrendsGraphProps) {
	const { maxRange, minRange } = getRangeMinMax({ data, filterMinZero: true });

	let GRAPH_Y_GAP = 2;

	if (maxRange - minRange < 10) {
		GRAPH_Y_GAP = 2;
	} else if (maxRange - minRange < 20) {
		GRAPH_Y_GAP = 4;
	} else if (maxRange - minRange < 50) {
		GRAPH_Y_GAP = 6;
	}

	const metric = METRIC_CONSTANTS.temperature;
	const { max, min } = parseGraphData({ data, RANGE_GAP: GRAPH_Y_GAP, filterMinZero: true });
	const gridYValues = generateRange(min, max, GRAPH_Y_GAP);
	const markers = getChartMarkers(data);
	const parsedData: Datum[] = data.map(d => (d.y === 0.0 ? { ...d, y: null } : d));
	const separatedData = separateData(parsedData);

	const chartsData: Serie[] = separatedData.map((dataArray, index) => ({
		id: dataArray[0].y === null ? `temp-null-${index + 1}` : `temp-${index + 1}`,
		data: dataArray,
	}));

	return (
		<LineChart
			metric={metric}
			yScale={{ type: 'linear', min, max }}
			data={chartsData}
			markers={markers}
			axisBottom={{
				renderTick: YAxis,
			}}
			areaBaselineValue={min}
			gridYValues={gridYValues}
			sliceTooltip={props => {
				return <GraphTooltip tooltipProps={props} metric={metric} />;
			}}
		/>
	);
}

function separateData(data: Datum[]) {
	const finalOutput = [];
	let currentOutput = [];

	for (let i = 0; i < data.length; i++) {
		if (currentOutput.length === 0) {
			currentOutput.push(data[i]);
		} else if (data[i].y === null && currentOutput[currentOutput.length - 1].y === null) {
			currentOutput.push(data[i]);
		} else if (data[i].y === null) {
			finalOutput.push(currentOutput);
			currentOutput = [];
			currentOutput.push(data[i]);
		} else if (currentOutput[currentOutput.length - 1].y === null) {
			finalOutput.push(currentOutput);
			currentOutput = [];
			currentOutput.push(data[i]);
		} else {
			currentOutput.push(data[i]);
		}
	}

	finalOutput.push(currentOutput);

	return finalOutput;
}
