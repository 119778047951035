'use client';

import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Loader2Icon, X } from 'lucide-react';
import { isAxiosError } from 'axios';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

import { downloadWeatherData } from '@/api/weather';
import { useWeatherAccount } from '@/api/dashboard';
import { useZomatoUser } from '@/hooks/useZomatoUser';
import { Button } from '@/ui/button';
import { Label } from '@/ui/label';
import { useToast } from '@/ui/use-toast';
import { useAppStore } from '@/lib/store';
import { useTracker } from '@/lib/tracking/useTracker';
import { WeatherDeviceType } from '@/types/constants';

import { DownloadButtonBottom } from './DownloadHandlers';
import { DownloadDataLocalitySelection } from './DownloadDataLocalitySelection';

const DOWNLOAD_DATA_RANGE = [
	{
		label: 'Last month',
		value: '30',
	},
	{
		label: 'Last 2 months',
		value: '60',
	},
	{
		label: 'Last 3 months',
		value: '90',
	},
	{
		label: 'Last 6 months',
		value: '180',
	},
];

interface DownloadDataProps extends React.ComponentProps<typeof Button> {
	buttonClassName?: string;
	origin: 'TRENDS_SECTION' | 'API_CALLOUT_SECTION';
}

export function DownloadData({ buttonClassName, origin, ...buttonProps }: DownloadDataProps) {
	const { toast } = useToast();
	const { trackEvent } = useTracker();

	const parsedWeatherData = useAppStore(state => state.parsedWeatherData);
	const [selectedDateRange, setSelectedDateRange] = useState<string>();
	const [selectedLocality, setSelectedLocality] = useState<string>();
	const [selectedCity, setselectedCity] = useState<string>();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { data: zomatoUserData, isAuthLoading, isRegistered, isLoggedIn } = useZomatoUser();
	const { data: weatherAccountData } = useWeatherAccount({
		enabled: isLoggedIn && isRegistered,
	});

	const mutation = useMutation({
		mutationFn: downloadWeatherData,
		onError: error => {
			const errorMessage = isAxiosError(error) ? error.response?.data?.message : error.message;

			toast({
				variant: 'default',
				description: errorMessage,
			});

			trackEvent({
				event_type: 'ERROR_BOUNDARY',
				component: 'DOWNLOAD_DATA',
				ui_source: origin,
				meta_data: {
					error: error.message,
				},
			});
		},
		onSuccess: data => {
			window.open(data.link, '_blank');
			setIsModalOpen(false);
		},
	});

	const selectedLocalityData =
		parsedWeatherData && selectedCity && selectedLocality
			? parsedWeatherData.data[selectedCity].localities[selectedLocality]
			: null;

	useEffect(() => {
		/**
		 * DEV: This is a quick fix because radix adds pointer events none to body
		 * which doesn't allow us to use headlessui Popover
		 * more on: https://github.com/radix-ui/primitives/issues/2122
		 **/

		if (isModalOpen) {
			// Pushing the change to the end of the call stack
			const timer = setTimeout(() => {
				document.body.style.pointerEvents = '';
			}, 0);

			return () => clearTimeout(timer);
		} else {
			document.body.style.pointerEvents = 'auto';
		}
	}, [isModalOpen]);

	if (!zomatoUserData || !isLoggedIn) {
		return (
			<DownloadButtonBottom mode="login" className={buttonClassName} {...buttonProps} isLoading={isAuthLoading} />
		);
	}

	// TOOD: Review logic here
	if (!isRegistered || !weatherAccountData) {
		return (
			<DownloadButtonBottom
				mode="onboarding"
				className={buttonClassName}
				{...buttonProps}
				isLoading={isAuthLoading}
			/>
		);
	}

	const downloadButtonHandler = () => {
		trackEvent({
			event_type: 'CLICK',
			component: 'DOWNLOAD_DATA',
			ui_source: origin,
			meta_data: {
				value: selectedDateRange,
				city_id: selectedCity,
				locality_id: selectedLocality,
			},
		});

		mutation.mutate({
			duration_day: selectedDateRange,
			locality_id: selectedLocality,
			user_api_key: weatherAccountData.user_api_key,
			city_id: selectedCity,
		});
	};

	const isSubmitDisabled = !(selectedDateRange && selectedLocality) || mutation.isPending;

	return (
		<>
			<DownloadButtonBottom
				mode="dashboard"
				className={buttonClassName}
				isLoading={isAuthLoading}
				{...buttonProps}
				onClick={() => setIsModalOpen(true)}
			/>
			<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<DialogBackdrop className="fixed inset-0 z-20 bg-black/30" />
				<div className="fixed inset-0 z-50 flex w-screen items-center justify-center p-4">
					<DialogPanel className="flex w-full max-w-lg flex-col items-center justify-center gap-4 overflow-hidden rounded-lg border bg-background text-primary shadow-lg duration-200 md:max-w-3xl md:rounded-2xl">
						<DialogTitle className="flex w-full items-center justify-between border-b border-gray-200 p-4 py-3 md:p-4">
							<h2 className="w-full border-gray-200 text-left text-lg font-semibold">
								Download past data
							</h2>
							<button
								onClick={() => setIsModalOpen(false)}
								className="opacity-70 transition hover:opacity-100"
							>
								<X className="mt-0 size-6 md:size-8" />
								<span className="sr-only">Close</span>
							</button>
						</DialogTitle>
						<div className="flex w-full flex-col items-start justify-center gap-4 px-4 md:gap-8">
							<div className="flex flex-col gap-4 overflow-hidden">
								<Label className="text-base font-normal">Select date range</Label>
								<div className="no-scrollbar flex max-w-fit flex-row flex-wrap justify-evenly gap-2 overflow-scroll sm:overflow-hidden">
									{DOWNLOAD_DATA_RANGE.map(dateRange => {
										if (selectedDateRange === dateRange.value) {
											return (
												<Button
													size="sm"
													variant="outline"
													className="w-full rounded-full border-2 border-[#EF4F5F] bg-[#FFF5F6] text-[#EF4F5F] hover:bg-[#FFF5F6] hover:text-[#E03546] md:w-fit"
													key={dateRange.value}
												>
													<span className="font-normal">{dateRange.label}</span>
												</Button>
											);
										} else {
											return (
												<Button
													size="sm"
													variant="outline"
													className="w-full rounded-full border-2 border-gray-200 bg-white hover:bg-gray-100 md:w-fit"
													key={dateRange.value}
													onClick={() => {
														setSelectedDateRange(dateRange.value);
													}}
												>
													<span className="font-normal text-gray-500">{dateRange.label}</span>
												</Button>
											);
										}
									})}
								</div>
							</div>
							<div className="flex w-full flex-col gap-4">
								<Label className="text-base font-normal">Select a city and area</Label>
								<DownloadDataLocalitySelection
									selectedCity={selectedCity}
									setSelectedCity={setselectedCity}
									selectedLocality={selectedLocality}
									setSelectedLocality={setSelectedLocality}
								/>
								<h4 className="text-sm font-normal text-red-400">
									{selectedLocalityData?.device_type === WeatherDeviceType.RAIN_GAUGE &&
										'This city has rain gauge system. Only rain information is available. '}
								</h4>
							</div>
						</div>
						<div className="flex w-full flex-col-reverse border-t border-gray-200 p-4 py-3 sm:flex-row sm:justify-end sm:space-x-2 md:p-4">
							<Button
								type="submit"
								variant={isSubmitDisabled ? 'disabled' : 'zomato'}
								disabled={isSubmitDisabled}
								onClick={downloadButtonHandler}
								className={'w-full sm:w-48'}
							>
								{mutation.isPending ? <Loader2Icon className="mr-2 animate-spin" /> : null}
								Download
							</Button>
						</div>
					</DialogPanel>
				</div>
			</Dialog>
		</>
	);
}
