'use client';

import React, { Suspense, useState } from 'react';

import { useWeatherTrendsData } from '@/api/weather';
import { useAppStore } from '@/lib/store';
import { Skeleton } from '@/ui/skeleton';
import { WeatherModes } from '@/types/constants';

import { HumidityChart } from './HumidityChart';
import { RainChart } from './RainChart';
import { WindChart } from './WindChart';
import { METRIC_CONSTANTS } from '../WeatherMap/utils';
import { getTrendsData, isTrendDowntime } from './utils';
import { ErrorComponentOverlay } from './GraphErrorComponent';
import { TrendsWeatherModes } from './TrendsWeatherModes';
import { TemperatureChart } from './TemperatureChart';
import { DownloadData } from '../DownloadSection/DownloadData';
import { ClientErrorBoundary } from '../ClientErrorBoundary';

function getTrendsGraph({ data, selectedMode }: { selectedMode: WeatherModes; data: HourlyTrendsData[] }) {
	const isDowntime = isTrendDowntime(data, selectedMode);

	const graphData = getTrendsData({
		data: data,
		mode: selectedMode,
		preParse: value => {
			const parsedValue = METRIC_CONSTANTS[selectedMode].parser(value);
			return parsedValue ? parsedValue : null;
		},
	});

	let GraphComponent: (props: TrendsGraphProps) => React.ReactElement | null = () => null;

	if (isDowntime) {
		return <ErrorComponentOverlay data={graphData} selectedMode={selectedMode} />;
	}

	switch (selectedMode) {
		case WeatherModes.TEMPERATURE: {
			GraphComponent = TemperatureChart;
			break;
		}
		case WeatherModes.RAIN_ACCUMULATION: {
			GraphComponent = RainChart;
			break;
		}
		case WeatherModes.HUMIDITY: {
			GraphComponent = HumidityChart;
			break;
		}
		case WeatherModes.WIND: {
			GraphComponent = WindChart;
			break;
		}
	}

	return <GraphComponent data={graphData} />;
}

export function TrendsCard() {
	const [selectedMode, setSelectedMode] = useState<WeatherModes>(WeatherModes.TEMPERATURE);
	const locationSelectedCity = useAppStore(state => state.locationSelectedCity);
	const locationSelectedLocality = useAppStore(state => state.locationSelectedLocality);
	const selectedLocalityData = useAppStore(state => state.selectedLocalityData);
	const [isModesDisabled, setIsModesDisabled] = useState(false);

	const { data, isLoading, error, status, isError } = useWeatherTrendsData({
		city_id: locationSelectedCity,
		locality_id: locationSelectedLocality,
	});

	const TrendsGraph = () => {
		return (
			<>
				{data && <div className="h-60">{getTrendsGraph({ data: data.weather_trends_data, selectedMode })}</div>}
			</>
		);
	};

	return (
		<div className="w-full overflow-hidden rounded-2xl border border-gray-200 bg-background p-4 md:w-full md:px-8 md:pb-0 md:pt-6 lg:w-2/3">
			<div className="flex flex-col gap-0 sm:gap-0">
				<div className="flex justify-between pb-4">
					<h2 className="flex flex-col text-ellipsis text-2xl font-semibold text-primary md:text-2xl">
						{selectedLocalityData ? (
							`Weather trends in ${selectedLocalityData.locality_name}`
						) : (
							<Skeleton className="h-8 w-60" />
						)}
						<span className="text-sm font-medium text-secondary">Data for last 12 hours</span>
					</h2>
					<div className="hidden md:block">
						<Suspense>
							<DownloadData
								size="xs"
								variant="outline"
								buttonClassName="text-base text-red-500 border border-red-500 hover:border-rose-500 hover:text-rose-500 hover:bg-red-100/40"
								origin="TRENDS_SECTION"
							/>
						</Suspense>
					</div>
				</div>
				<TrendsWeatherModes
					isDataLoading={isLoading}
					selectedMode={selectedMode}
					setSelectedMode={setSelectedMode}
					isForceDisabled={isModesDisabled}
				/>
			</div>
			{(isLoading || status === 'pending') && (
				<div className="my-6 flex flex-col gap-4 sm:gap-2">
					<Skeleton className="h-60 w-full" />
				</div>
			)}
			{error && <GraphErrorComponent message={`Error fetching data: ${error.message}`} />}
			<ClientErrorBoundary
				fallback={<GraphErrorComponent message="An unexpected error occured while displaying trends" />}
				onError={() => setIsModesDisabled(true)}
			>
				<TrendsGraph />
			</ClientErrorBoundary>
			<div className="md:hidden">
				<Suspense>
					<DownloadData
						size="xs"
						variant="outline"
						buttonClassName="text-base text-red-500 border border-red-500 hover:border-rose-500 hover:text-rose-500 hover:bg-rose-50"
						origin="TRENDS_SECTION"
					/>
				</Suspense>
			</div>
		</div>
	);
}

export function GraphErrorComponent({ message }: { message: string }) {
	return (
		<div className="mt-8 flex flex-col gap-4 sm:gap-2">
			<div className="flex h-56 w-full items-center justify-center text-gray-500">{message}</div>
		</div>
	);
}
