import { useEffect, useMemo } from 'react';

import { useAppStore } from '@/lib/store';
import { METRIC_CONSTANTS } from '@/components/WeatherMap/utils';
import { WeatherModes } from '@/types/constants';

interface UseWeatherModesProps {
	mapSelectedMode: WeatherModes | null;
	setMapSelectedMode: (mode: WeatherModes) => void;
	excludedModes?: WeatherModes[];
}

export function useWeatherModes({ mapSelectedMode, setMapSelectedMode, excludedModes = [] }: UseWeatherModesProps) {
	const selectedLocalityData = useAppStore(state => state.selectedLocalityData);

	const AVAILABLE_WEATHER_MODES = useMemo(
		() =>
			Object.values(METRIC_CONSTANTS).filter(mode => {
				if (excludedModes.includes(mode.id)) {
					return false;
				}

				if (selectedLocalityData && mode.deviceTypes.includes(selectedLocalityData.device_type)) {
					return mode;
				}
			}),
		[selectedLocalityData],
	);

	useEffect(() => {
		if (AVAILABLE_WEATHER_MODES.length === 1) {
			setMapSelectedMode(AVAILABLE_WEATHER_MODES[0].id);
		} else if (AVAILABLE_WEATHER_MODES.length > 1) {
			setMapSelectedMode(mapSelectedMode ?? AVAILABLE_WEATHER_MODES[0].id);
		}
	}, [AVAILABLE_WEATHER_MODES, setMapSelectedMode, mapSelectedMode]);

	return {
		AVAILABLE_WEATHER_MODES,
		mapSelectedMode,
		setMapSelectedMode,
	};
}
