'use client';

import { Dispatch, SetStateAction } from 'react';

import { LocalitySelectionHolder } from '../LocationSelectorHolder';

interface LocalitySelectionProps {
	selectedCity: string | undefined;
	setSelectedCity: Dispatch<SetStateAction<string | undefined>>;
	selectedLocality: string | undefined;
	setSelectedLocality: Dispatch<SetStateAction<string | undefined>>;
}

export function DownloadDataLocalitySelection({
	selectedCity,
	setSelectedCity,
	selectedLocality,
	setSelectedLocality,
}: LocalitySelectionProps) {
	const onCityChange = (cityId: string) => {
		setSelectedCity(cityId);
		setSelectedLocality(undefined);
	};

	return (
		<div className={'container flex w-full flex-col justify-start gap-4 px-0 sm:flex-row'}>
			<LocalitySelectionHolder
				selectedCity={selectedCity}
				setSelectedCity={onCityChange}
				selectedLocality={selectedLocality}
				setSelectedLocality={setSelectedLocality}
				className="border-2 border-gray-200 shadow-none"
			/>
		</div>
	);
}
