import { AlertTriangleIcon, Loader2Icon } from 'lucide-react';
import { useMap } from 'react-leaflet';
import { useEffect, useMemo, useState } from 'react';

import { useAppStore } from '@/lib/store';
import { useCityWeatherData } from '@/api/weather';

export function MapBottomCallouts() {
	const map = useMap();

	const [hideCallout, setHideCallout] = useState(false);
	const mapLiveLocationEnabled = useAppStore(state => state.mapLiveLocationEnabled);
	const locationSelectedLocality = useAppStore(state => state.locationSelectedLocality);
	const locationSelectedCity = useAppStore(state => state.locationSelectedCity);
	const setMapLiveLocationEnabled = useAppStore(state => state.setMapLiveLocationEnabled);
	const { data: cityWeatherData, isRefetching, isLoading } = useCityWeatherData(locationSelectedCity);

	useEffect(() => {
		setHideCallout(false);
	}, [mapLiveLocationEnabled]);

	useEffect(() => {
		setHideCallout(true);
		setMapLiveLocationEnabled('idle');
	}, [locationSelectedLocality]);

	useEffect(() => {
		if (!cityWeatherData) {
			map.dragging.disable();
			map.scrollWheelZoom.disable();
		} else {
			map.dragging.enable();
			map.scrollWheelZoom.enable();
		}
	}, [cityWeatherData]);

	useEffect(() => {
		map.on('drag', () => {
			setHideCallout(true);
		});

		return () => {
			map.off('drag');
		};
	}, [map]);

	const selectedCityWeatherData =
		cityWeatherData && locationSelectedLocality && cityWeatherData.data[locationSelectedLocality];
	const selectedLocalityWeatherData = selectedCityWeatherData && selectedCityWeatherData.weather_metrics;

	const isDeviceDowntime = useMemo(() => {
		return selectedCityWeatherData && selectedLocalityWeatherData
			? Object.values(selectedLocalityWeatherData)
					.filter(value => value !== 0)
					.every(value => value === null)
			: false;
	}, [selectedCityWeatherData, selectedLocalityWeatherData]);

	useEffect(() => {
		if (isDeviceDowntime) {
			setHideCallout(false);
		}
	}, [isDeviceDowntime]);

	return (
		<div className="container absolute bottom-0 z-10 mb-20 mr-4 grid w-full md:mb-4">
			{mapLiveLocationEnabled === 'loading' && (
				<div className="flex h-10 w-fit items-center justify-self-center whitespace-nowrap rounded-full border border-white bg-gray-600 px-4 text-white animate-in fade-in-0">
					<Loader2Icon className="mr-2 animate-spin" />
					<h6 className="text-sm font-normal">Fetching Live Geo Location</h6>
				</div>
			)}
			{isDeviceDowntime &&
				!hideCallout &&
				mapLiveLocationEnabled !== 'loading' &&
				mapLiveLocationEnabled !== 'not-found' && (
					<div className="flex w-fit flex-row items-center gap-2 justify-self-center rounded-lg bg-slate-800 fill-white p-2 text-white md:px-4">
						<AlertTriangleIcon size={20} fill="white" className="text-slate-800" />
						<h4 className="text-xs font-normal text-white md:text-base">
							Sorry, data is temporarily unavailable. It will be live back soon
						</h4>
					</div>
				)}
			{!hideCallout && mapLiveLocationEnabled === 'not-found' && (
				<div className="flex w-fit flex-row items-center gap-2 justify-self-center rounded-lg bg-slate-800 fill-white p-2 text-white md:px-4">
					<AlertTriangleIcon size={20} fill="white" className="text-slate-800" />
					<h4 className="text-xs font-normal text-white md:text-base">
						Sorry, we are not available in your area yet
					</h4>
				</div>
			)}
			{(!cityWeatherData || isLoading) && (
				<div className="flex h-10 w-fit items-center justify-self-center whitespace-nowrap rounded-full border border-white bg-gray-600 px-4 text-white animate-in fade-in-0">
					<Loader2Icon className="mr-2 animate-spin" />
					<h6 className="text-sm font-normal">Fetching Live Data</h6>
				</div>
			)}
		</div>
	);
}
