import { useRouter } from 'next/navigation';
import React, { forwardRef } from 'react';
import { Loader2Icon } from 'lucide-react';

import { Button } from '@/ui/button';
import { useTracker } from '@/lib/tracking/useTracker';

export interface DownloadButtonBottomProps extends React.ComponentProps<typeof Button> {
	mode: 'login' | 'onboarding' | 'dashboard';
	isLoading?: boolean;
}

// eslint-disable-next-line react/display-name
export const DownloadButtonBottom = forwardRef<HTMLButtonElement, DownloadButtonBottomProps>(
	({ mode, isLoading, ...props }, ref) => {
		const router = useRouter();
		const { trackEvent } = useTracker();

		return (
			<Button
				ref={ref}
				variant={isLoading ? 'disabled' : 'zomato'}
				size="xl"
				disabled={isLoading}
				onClick={() => {
					trackEvent({
						event_type: 'CLICK',
						component: 'DOWNLOAD_DATA',
						ui_source: 'FREE_API_SECTION',
						meta_data: { state: mode },
					});
					if (mode === 'login') {
						router.push('/login');
					} else if (mode === 'onboarding') {
						router.push('/onboarding');
					} else if (mode === 'dashboard') {
						router.push('/dashboard');
					}
				}}
				{...props}
			>
				{isLoading && <Loader2Icon size={18} className="mr-2 animate-spin" />}
				Download past data
			</Button>
		);
	},
);
