import { generateRange } from '@/lib/round';
import { useIsMobile } from '@/hooks/useIsMobile';

import BarChart from './Charts/Bar';
import YAxis from './YAxis';
import { METRIC_CONSTANTS } from '../WeatherMap/utils';
import { BAR_GRAPH_COLOR, getChartMarkers, getRangeMinMax, parseGraphData } from './utils';
import { GraphTooltip } from './GraphTooltip';
import { BarFallbackWithValue } from './BarFallbackValue';

export function WindChart({ data }: { data: GraphDataProps[] }) {
	const { maxRange, minRange } = getRangeMinMax({ data, floor: 0 });

	let GRAPH_Y_GAP = 5;

	if (maxRange - minRange < 2) {
		GRAPH_Y_GAP = 0.6;
	} else if (maxRange - minRange < 4) {
		GRAPH_Y_GAP = 1;
	} else if (maxRange - minRange < 8) {
		GRAPH_Y_GAP = 2;
	} else if (maxRange - minRange < 20) {
		GRAPH_Y_GAP = 6;
	} else if (maxRange - minRange < 50) {
		GRAPH_Y_GAP = 10;
	}

	const metric = METRIC_CONSTANTS.wind_speed;
	const { max, min } = parseGraphData({ data, RANGE_GAP: GRAPH_Y_GAP });
	const gridYValues = generateRange(0, max, GRAPH_Y_GAP);
	const markers = getChartMarkers(data);
	const isMobile = useIsMobile();

	return (
		<BarChart
			data={data}
			axisBottom={{
				renderTick: YAxis,
			}}
			minValue={0}
			maxValue={max}
			gridYValues={gridYValues}
			colors={[BAR_GRAPH_COLOR]}
			tooltip={props => {
				return <GraphTooltip tooltipProps={props} metric={metric} />;
			}}
			layers={['grid', 'axes', 'bars', BarFallbackWithValue({ value: 'NA', isMobile: isMobile }), 'markers']}
			axisLeft={{
				renderTick: ({ x, y, value }) => {
					if (Array.isArray(gridYValues)) {
						if (gridYValues.includes(value))
							return (
								<g key={value} transform={`translate(${x},${y})`}>
									<text x={-52} y={0} className="fill-gray-500 text-xs font-normal" dy=".32rem">
										{value}
										{METRIC_CONSTANTS.wind_speed.unitPrefix}
										{METRIC_CONSTANTS.wind_speed.unit}
									</text>
								</g>
							);
					}
					return <g></g>;
				},
			}}
		/>
	);
}
