import React from 'react';

import { useWeatherModes } from '@/hooks/useWeatherModes';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/ui/tooltip';
import { Button } from '@/ui/button';
import { useAppStore } from '@/lib/store';
import { WeatherDeviceType, WeatherModes } from '@/types/constants';
import { useTracker } from '@/lib/tracking/useTracker';

import { getTrendsControlData } from '../WeatherMap/utils';

interface TrendsWeatherModesProps {
	selectedMode: WeatherModes;
	setSelectedMode: React.Dispatch<React.SetStateAction<WeatherModes>>;
	isDataLoading: boolean;
	isForceDisabled?: boolean;
}

export function TrendsWeatherModes({
	setSelectedMode,
	isDataLoading,
	selectedMode,
	isForceDisabled,
}: TrendsWeatherModesProps) {
	const selectedLocalityData = useAppStore(state => state.selectedLocalityData);
	const { trackEvent } = useTracker();
	const { AVAILABLE_WEATHER_MODES } = useWeatherModes({
		mapSelectedMode: selectedMode,
		setMapSelectedMode: setSelectedMode,
		excludedModes: [WeatherModes.RAIN_INTENSITY],
	});

	const mapControlData = getTrendsControlData(selectedLocalityData, [WeatherModes.RAIN_INTENSITY]);

	const WrapperComponent = ({ children }: { children: React.ReactNode }) => {
		return selectedLocalityData && selectedLocalityData.device_type === WeatherDeviceType.RAIN_GAUGE ? (
			<Tooltip>
				<TooltipTrigger className="cursor-not-allowed self-end" asChild>
					{children}
				</TooltipTrigger>
				<TooltipContent className="z-10 bg-slate-800 text-xs font-normal text-white">
					<span>This area only has rain gauge system</span>
					<TooltipArrow className="z-0 size-2 -translate-y-1.5 rotate-45 bg-slate-800" />
				</TooltipContent>
			</Tooltip>
		) : (
			<>{children}</>
		);
	};

	return (
		<>
			{mapControlData.length > 0 && (
				<div className="no-scrollbar -mx-4 flex flex-row gap-2 overflow-scroll p-2 px-4 sm:overflow-hidden">
					{mapControlData.map(mode => {
						const isDisabled =
							isForceDisabled ||
							isDataLoading ||
							!AVAILABLE_WEATHER_MODES.map(mode => mode.id).includes(mode.id);

						if (selectedMode === mode.id) {
							return (
								<Button
									disabled={isDisabled}
									size="sm"
									variant="outline"
									className="rounded-full border border-[#EF4F5F] bg-[#FFF5F6] capitalize text-[#EF4F5F] hover:bg-[#FFF5F6] hover:text-[#E03546]"
									key={mode.id}
								>
									<mode.icon className="mr-2 size-5" />
									<span className="text-xs font-normal md:text-sm">{mode.label}</span>
								</Button>
							);
						} else {
							return (
								<WrapperComponent key={mode.id}>
									<Button
										disabled={isDisabled}
										size="sm"
										variant={'outline'}
										className="rounded-full border capitalize text-[#596378]"
										onClick={() => {
											setSelectedMode(mode.id);
											trackEvent({
												event_type: 'CLICK',
												component: 'WEATHER_MODE',
												ui_source: 'TRENDS_SECTION',
												meta_data: {
													value: mode.id,
												},
											});
										}}
									>
										<mode.icon className="mr-2 size-5" />
										<span className="text-xs font-normal md:text-sm">{mode.label}</span>
									</Button>
								</WrapperComponent>
							);
						}
					})}
				</div>
			)}
		</>
	);
}
