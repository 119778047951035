export const getBrowserPermissions = async () => {
	const isServer = typeof window === 'undefined';

	if (isServer) {
		return {
			geolocation: 'unsupported',
		};
	}

	if (!navigator.permissions || !navigator.permissions.query) {
		return {
			geolocation: 'unsupported',
		};
	}

	const permission = await navigator.permissions.query({ name: 'geolocation' });

	return {
		geolocation: permission.state,
	};
};
