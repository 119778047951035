import { animated } from '@react-spring/web';

import { convertTo12HourFormat } from '@/lib/round';
import { useIsMobile } from '@/hooks/useIsMobile';

export interface YAxisProps {
	x: number;
	y: number;
	value: any;
	tickIndex: any;
}

const YAxis = ({ x, y, value, tickIndex }: YAxisProps) => {
	value = Number(value);
	const hour = convertTo12HourFormat(value);
	const isMobile = useIsMobile();

	if (isMobile)
		return (
			<animated.g key={value} transform={`translate(${x},${y})`}>
				{tickIndex % 2 === 0 ? (
					<>
						<line x1={0} y1={0} x2={0} y2={5} stroke="rgb(107 114 128)" />
						<text y={16} x={-16} className="fill-gray-400 text-xs font-normal">
							{hour}
						</text>
					</>
				) : (
					<line x1={0} y1={0} x2={0} y2={5} stroke="#e5e7eb" />
				)}
			</animated.g>
		);

	return (
		<animated.g key={value} transform={`translate(${x},${y})`}>
			<text y={28} x={-16} className="fill-gray-400 text-xs font-normal">
				{hour}
			</text>
		</animated.g>
	);
};

export default YAxis;
