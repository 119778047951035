'use client';

import { useEffect, useState } from 'react';
import { ChevronsDown } from 'lucide-react';

import { SECTION_VIEW_TRACKING } from '@/types/constants';
import { ComponentTracker } from '@/lib/tracking/ComponentTracker';

import { scrollToMapSection } from './HeroSection/clientActions';

export default function LiveWeatherFAB() {
	const [showFAB, setShowFAB] = useState(false);

	useEffect(() => {
		const viewLiveWeatherButton = document.getElementById('view-live-weather-button');
		const mapSection = document.getElementById(SECTION_VIEW_TRACKING.MAP_VIEW_SECTION);

		if (!mapSection || !viewLiveWeatherButton) return;

		const observerHandler = (entries: IntersectionObserverEntry[]) => {
			if (entries?.[0]?.isIntersecting || entries?.[1]?.isIntersecting) {
				setShowFAB(false);
				return;
			}

			// If user is below map section then hide pill
			if (
				entries?.[0].target.id === SECTION_VIEW_TRACKING.MAP_VIEW_SECTION &&
				entries[0].boundingClientRect.y < 0
			) {
				setShowFAB(false);
				return;
			}

			setShowFAB(true);
		};

		const observer = new IntersectionObserver(observerHandler);

		observer.observe(viewLiveWeatherButton);
		observer.observe(mapSection);

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<ComponentTracker
			event={{ event_type: 'CLICK', component: 'VIEW_LIVE_WEATHER_FAB', ui_source: 'LANDING_PAGE' }}
			onClick={scrollToMapSection}
		>
			<div
				className={`fixed inset-x-0 bottom-10 z-50 mx-auto w-fit cursor-pointer rounded-full border-2 border-white bg-black/60 px-4 py-2 text-base text-white opacity-0 shadow-xl transition-all duration-500 hover:bg-black/70 ${showFAB ? 'visible opacity-100' : 'invisible opacity-0'}`}
			>
				<span className="flex items-center gap-2">
					View live weather
					<ChevronsDown size={16} />
				</span>
			</div>
		</ComponentTracker>
	);
}
