import { labelParser } from '@/components/WeatherMap/utils';

export const roundToNextMultiple = (multiple: number) => (number: number) => {
	if (number === 0) {
		return number;
	}

	const roundedAbsolute = Math.ceil(Math.abs(number) / multiple) * multiple;
	const sign = Math.sign(number);
	const roundedValue = roundedAbsolute === 0 ? multiple : roundedAbsolute * sign;
	return roundedValue;
};

export const roundToPreviousMultiple = (multiple: number) => (number: number) => {
	if (number === 0) {
		return number;
	}

	const roundedAbsolute = Math.floor(Math.abs(number) / multiple) * multiple;
	const sign = Math.sign(number);
	const roundedValue = roundedAbsolute * sign;
	return roundedValue;
};

export const formatNumber = (number: number) => {
	const formattedNumber = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 1,
	}).format(number);
	return Number(formattedNumber);
};

export const generateRange = (min = 0, max = 1, difference = 1) => {
	min = Number.isFinite(min) ? min : 0;
	max = Number.isFinite(max) ? max : 1;

	if (min >= max) {
		[min, max] = [max, min];
	}

	difference = difference > 0 ? difference : 1;

	const result = [];
	for (let i = min; i <= max; i += difference) {
		const value = Math.ceil(i);
		result.push(value);
	}
	return result;
};

export const convertTo12HourFormat = (hour24 = 0) => {
	if (hour24 < 0 || hour24 > 23) {
		throw new Error('Invalid hour value. Hour must be between 0 and 23.');
	}

	const hour12 = hour24 % 12 || 12; // Convert 0 to 12
	const period = hour24 < 12 ? 'am' : 'pm';

	return labelParser(hour12, period);
};
