import { SliceTooltipProps } from '@nivo/line';
import React from 'react';
import { BarTooltipProps } from '@nivo/bar';

import { labelParser } from '../WeatherMap/utils';

interface GraphTooltipProps {
	tooltipProps: SliceTooltipProps | BarTooltipProps<any>;
	metric: MetricConfig;
}

export function _GraphTooltip({ tooltipProps, metric }: GraphTooltipProps) {
	return (
		<div className="relative w-fit rounded-md bg-gray-600 px-2 py-1 text-xs font-light text-white">
			{/* <div className="absolute bottom-0 left-1/2 size-2 -translate-x-1/2 translate-y-1/2 rotate-45 rounded-ee-[3px] bg-gray-600"></div> */}
			<div className="flex flex-col gap-2">
				{'slice' in tooltipProps ? (
					tooltipProps.slice.points.map(point => (
						<div key={point.id}>{labelParser(point.data.yFormatted, metric.unit)}</div>
					))
				) : (
					<div key={tooltipProps.id}>{labelParser(tooltipProps.value, metric.unit)}</div>
				)}
			</div>
		</div>
	);
}

export const GraphTooltip = React.memo(_GraphTooltip);
