//@ts-nocheck
import { linearGradientDef } from '@nivo/core';
import { CustomLayerProps, LineSvgProps, ResponsiveLine } from '@nivo/line';
import { FC } from 'react';

import { useIsMobile } from '@/hooks/useIsMobile';

import { labelParser } from '../../WeatherMap/utils';
import { getChartMarkers } from '../utils';

export interface LineChartProps extends LineSvgProps, TrendsGraphProps {
	metric: MetricConfig;
}

const Line = ({ series, lineGenerator, xScale, yScale }: CustomLayerProps) => {
	return series.map(({ entityType, data, color, lineType, id }) => (
		<path
			key={`${entityType}_${id}`}
			d={lineGenerator(
				data.map(d => ({
					x: xScale(d.data.x),
					y: yScale(d.data.y),
				})),
			)}
			fill="none"
			stroke={color}
			style={
				lineType === 'dashed'
					? {
							strokeDasharray: '4, 6',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeLinecap: 'square',
						}
					: {
							strokeWidth: 2,
						}
			}
		/>
	));
};

export const LineChart: FC<LineChartProps> = ({ data = [], metric, ...otherProps }) => {
	const isMobile = useIsMobile();

	if (!data || data.length === 0) {
		console.warn('No data provided for LineChart');
		return null;
	}

	return (
		<ResponsiveLine
			data={data}
			margin={
				isMobile ? { top: 25, right: 10, bottom: 40, left: 50 } : { top: 28, right: 16, bottom: 60, left: 52 }
			}
			xScale={{ type: 'point' }}
			animate={true}
			pointSize={10}
			pointColor={'white'}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			// pointLabelYOffset={-12}
			useMesh={true}
			enableCrosshair={true}
			enableArea={true}
			areaOpacity={0.5}
			enableGridX={false}
			theme={{
				crosshair: {
					line: {
						stroke: '#AFB4C0',
						strokeWidth: 1,
						strokeDasharray: '4 4',
						strokeLinecap: 'round',
					},
				},
			}}
			colors={['#F8D149']}
			axisLeft={{
				renderTick: ({ x, y, value }) => {
					if (Array.isArray(otherProps?.gridYValues)) {
						if (otherProps.gridYValues.includes(value))
							return (
								<g key={`${x}_${y}_${value}`} transform={`translate(${x},${y})`}>
									<text x={-48} y={4} className="fill-gray-400 text-xs font-normal">
										{labelParser(value, metric.unit)}
									</text>
								</g>
							);
						return null;
					}
					return <g></g>;
				},
			}}
			curve="monotoneX"
			defs={[
				linearGradientDef('gradientA', [
					{ offset: 0, color: 'inherit' },
					{ offset: 80, color: 'inherit', opacity: 0 },
				]),
			]}
			fill={[{ match: '*', id: 'gradientA' }]}
			layers={['grid', 'markers', 'areas', Line, 'slices', 'points', 'axes', 'legends', 'crosshair']}
			enableSlices={'x'}
			{...otherProps}
		/>
	);
};
