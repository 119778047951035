import { CloudHailIcon, CloudRainWindIcon, CloudSunRainIcon, CloudyIcon, HelpCircleIcon } from 'lucide-react';
import { cva } from 'class-variance-authority';

import { Badge } from '@/ui/badge';
import { lexendFont } from '@/lib/utils';

import { IconBadgeProps } from './utils';
import { DownIcon } from './DownIcon';

enum RainIntensityModes {
	UNKNOWN = 0,
	NO = 1,
	LIGHT = 2,
	MODERATE = 3,
	HEAVY = 4,
}

function getRainBadgeConfig(rain: number) {
	switch (rain) {
		case 1:
			return {
				mode: RainIntensityModes.NO,
				displayValue: 'No rain',
				icon: CloudyIcon,
			};
		case 2:
			return {
				mode: RainIntensityModes.LIGHT,
				displayValue: 'Light rain',
				icon: CloudSunRainIcon,
			};
		case 3:
			return {
				mode: RainIntensityModes.MODERATE,
				displayValue: 'Moderate rain',
				icon: CloudHailIcon,
			};
		case 4:
			return {
				mode: RainIntensityModes.HEAVY,
				displayValue: 'Heavy rain',
				icon: CloudRainWindIcon,
			};
		default:
			return {
				mode: RainIntensityModes.UNKNOWN,
				displayValue: 'Unknown',
				icon: HelpCircleIcon,
			};
	}
}

const rainTwVairants = cva(
	`whitespace-nowrap rounded-full border border-white py-1 text-xs font-light shadow-md ${lexendFont.className}`,
	{
		variants: {
			rain: {
				[RainIntensityModes.UNKNOWN]: 'hidden',
				[RainIntensityModes.NO]: 'bg-[#EDF4FF] text-[#0E3272] hover:bg-[#EDF4FF]',
				[RainIntensityModes.LIGHT]: 'bg-[#DBE8FF] text-[#0E3272] hover:bg-[#DBE8FF]',
				[RainIntensityModes.MODERATE]: 'bg-[#9CC0FF] text-[#0E3272] hover:bg-[#9CC0FF]',
				[RainIntensityModes.HEAVY]: 'bg-[#538CEE] text-[#FFFFFF] hover:bg-[#538CEE]',
			},
		},
	},
);

export function RainIcon({ value, props, showUnavailable }: IconBadgeProps) {
	if (!value) {
		return <DownIcon props={props} showUnavailable={showUnavailable} value={value} />;
	}

	const modeValue = getRainBadgeConfig(value);

	return (
		<Badge className={rainTwVairants({ rain: modeValue.mode })} {...props}>
			<modeValue.icon size={18} className="mr-2" />
			{modeValue.displayValue}
		</Badge>
	);
}
