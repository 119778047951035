import { generateRange } from '@/lib/round';
import { useIsMobile } from '@/hooks/useIsMobile';

import BarChart from './Charts/Bar';
import YAxis from './YAxis';
import { labelParser, METRIC_CONSTANTS } from '../WeatherMap/utils';
import { BAR_GRAPH_COLOR, getChartMarkers, parseGraphData } from './utils';
import { GraphTooltip } from './GraphTooltip';
import { BarFallbackWithValue } from './BarFallbackValue';

export function HumidityChart({ data }: TrendsGraphProps) {
	const GRAPH_Y_GAP = 20;
	const metric = METRIC_CONSTANTS.humidity;
	const { max, min } = parseGraphData({ data, RANGE_GAP: GRAPH_Y_GAP });
	const gridYValues = generateRange(0, max, GRAPH_Y_GAP);
	const isMobile = useIsMobile();
	const markers = getChartMarkers(data);

	return (
		<BarChart
			data={data}
			axisBottom={{
				renderTick: YAxis,
			}}
			minValue={0}
			maxValue={max}
			gridYValues={gridYValues}
			colors={[BAR_GRAPH_COLOR]}
			tooltip={props => {
				return <GraphTooltip tooltipProps={props} metric={metric} />;
			}}
			layers={['grid', 'axes', 'bars', BarFallbackWithValue({ value: 'NA', isMobile: isMobile }), 'markers']}
			axisLeft={{
				tickSize: 0,
				renderTick: ({ x, y, value }) => {
					if (Array.isArray(gridYValues)) {
						if (gridYValues.includes(value))
							return (
								<g key={value} transform={`translate(${x},${y})`}>
									<text x={-48} y={0} className="fill-gray-500 text-xs font-normal" dy=".32rem">
										{labelParser(value, metric.unit)}
									</text>
								</g>
							);
						return <g></g>;
					}
					return (
						<g key={value} transform={`translate(${x},${y})`}>
							<text x={-48} y={0} className="fill-gray-500 text-xs font-normal" dy=".32rem">
								{labelParser(value, metric.unit)}
							</text>
						</g>
					);
				},
			}}
		/>
	);
}
