import WeatherWorking1Image from '@/public/weather-working-1.svg';
import WeatherWorking2Image from '@/public/weather-working-2.svg';
import WeatherWorking3Image from '@/public/weather-working-3.svg';
import WeatherWorking4Image from '@/public/weather-working-4.svg';
import WeatherWorking5Image from '@/public/weather-working-5.svg';
import HighlightsIcon1 from '@/icons/highlights-icon-1.svg';
import HighlightsIcon2 from '@/icons/highlights-icon-2.svg';
import HighlightsIcon3 from '@/icons/highlights-icon-3.svg';
import HighlightsIcon4 from '@/icons/highlights-icon-4.svg';
import HighlightsIcon5 from '@/icons/highlights-icon-5.svg';

export const weatherWorkingData = [
	{
		title: 'Weather device captures real time localised data',
		image: WeatherWorking1Image,
	},
	{
		title: 'ML models process this data for network optimisations',
		image: WeatherWorking2Image,
	},
	{
		title: 'Delivery partners are well informed & equipped to operate in any weather conditions',
		image: WeatherWorking3Image,
	},
	{
		title: 'More delivery partners are available to serve our customers during not-so-normal weather',
		image: WeatherWorking4Image,
	},
	{
		title: 'More restaurant choices for our customers',
		image: WeatherWorking5Image,
	},
];

export const weatherWorkingMetaData = [
	{
		value: '750+',
		title: 'Automated weather stations',
		icon: HighlightsIcon1,
	},
	{
		value: '12',
		title: 'Real-time weather parameters',
		icon: HighlightsIcon4,
	},
	{
		value: '60',
		title: 'Cities covered',
		icon: HighlightsIcon2,
	},
	{
		value: '1 min',
		title: 'Refresh rate',
		icon: HighlightsIcon3,
	},
	{
		value: '~96%',
		title: 'Signal accuracy',
		icon: HighlightsIcon5,
	},
];

export const WEATHER_WORKING_SECTION_TITLE = 'How our weather systems help Zomato serve you better';
export const WEATHER_SYSTEM_SECTION_TITLE = 'Key highlights of our weather infrastructure';
