import { RadioTowerIcon } from 'lucide-react';

import { Badge } from '@/ui/badge';
import { lexendFont } from '@/lib/utils';

import { METRIC_CONSTANTS } from '../utils';
import { getContrastYIQ, getMatchingStyles, IconBadgeProps, IDataSet } from './utils';
import { DownIcon } from './DownIcon';

const HUMIDITY_DATA_MODULARITY = 10;

const humidityData: IDataSet[] = [
	{
		key: 0,
		style: { backgroundColor: '#DC9D09' },
	},
	{
		key: 10,
		style: { backgroundColor: '#E9B501' },
	},
	{
		key: 20,
		style: { backgroundColor: '#F3C117' },
	},
	{
		key: 30,
		style: { backgroundColor: '#F8D149' },
	},
	{
		key: 40,
		style: { backgroundColor: '#FCDF82' },
	},
	{
		key: 50,
		style: { backgroundColor: '#FCEEC0' },
	},
	{
		key: 60,
		style: { backgroundColor: '#DBE8FF' },
	},
	{
		key: 70,
		style: { backgroundColor: '#9CC0FF' },
	},
	{
		key: 80,
		style: { backgroundColor: '#538CEE' },
	},
	{
		key: 90,
		style: { backgroundColor: '#1148A6' },
	},
	{
		key: 100,
		style: { backgroundColor: '#1148A6' },
	},
];

export function HumidityIcon({ value, props, showUnavailable }: IconBadgeProps) {
	if (!value) {
		return <DownIcon props={props} showUnavailable={showUnavailable} value={value} />;
	}

	const modeStyle = getMatchingStyles({ dataSet: humidityData, value, modularity: HUMIDITY_DATA_MODULARITY });
	const modeController = METRIC_CONSTANTS.humidity;

	return (
		<Badge
			className={`whitespace-nowrap rounded-full border border-white py-1 text-xs font-light text-white shadow-md ${lexendFont.className}`}
			style={{
				...modeStyle,
				color: getContrastYIQ(modeStyle.backgroundColor as string),
			}}
			{...props}
		>
			<RadioTowerIcon size={16} className="mr-1" strokeWidth={1.5} />
			{modeController.parser(value)}
			{modeController.unitPrefix}
			{modeController.unit}
		</Badge>
	);
}
