import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { API_BASE_PATH, API_HOST } from '@/lib/constants';

async function getWeatherAccountData(): Promise<WeatherUserProfileApiResponse> {
	const response = await axios.get(`${API_HOST}${API_BASE_PATH}/external/v0/user/get_weather_user_profile`);
	return response.data;
}

export function useWeatherAccount({ enabled }: { enabled: boolean }) {
	return useQuery({
		queryKey: ['weather_account_data'],
		queryFn: getWeatherAccountData,
		enabled: enabled,
		retry: 0,
		refetchOnWindowFocus: true,
	});
}
