import React from 'react';
import { BarCustomLayerProps } from '@nivo/bar';

import { cn } from '@/lib/utils';

interface BarFallbackValueProps {
	className?: string;
	value: string;
	isMobile?: boolean;
}

export const BarFallbackWithValue = ({ value, className, isMobile }: BarFallbackValueProps) => {
	// eslint-disable-next-line react/display-name
	return ({ bars }: BarCustomLayerProps<any>) => {
		return (
			<g>
				{bars.map(({ width, x, y, data: { value: barValue } }) => (
					<React.Fragment key={`${x}_${y}_${barValue}`}>
						{barValue === 0 && (
							<text
								x={x + width / 2}
								y={y - 10}
								textAnchor="middle"
								className={cn('fill-gray-500 text-xs font-normal ', className)}
								transform={`${isMobile && `rotate(-45, ${x + width / 2}, ${y - 10})`}`}
							>
								{value}
							</text>
						)}
					</React.Fragment>
				))}
			</g>
		);
	};
};
