import { generateRange } from '@/lib/round';
import { useIsMobile } from '@/hooks/useIsMobile';

import BarChart from './Charts/Bar';
import YAxis from './YAxis';
import { labelParser, METRIC_CONSTANTS } from '../WeatherMap/utils';
import { BAR_GRAPH_COLOR, getChartMarkers, getRangeMinMax, parseGraphData } from './utils';
import { GraphTooltip } from './GraphTooltip';
import { BarFallbackWithValue } from './BarFallbackValue';

export function RainChart({ data }: { data: GraphDataProps[] }) {
	const metric = METRIC_CONSTANTS.rain_accumulation;
	// const markers = getChartMarkers(data);
	const { maxRange, minRange } = getRangeMinMax({ data, filterMinZero: false });

	let GRAPH_Y_GAP = 1;

	if (maxRange - minRange > 2) {
		if (maxRange - minRange < 5) {
			GRAPH_Y_GAP = 2;
		} else if (maxRange - minRange < 10) {
			GRAPH_Y_GAP = 4;
		} else if (maxRange - minRange < 20) {
			GRAPH_Y_GAP = 6;
		} else if (maxRange - minRange < 30) {
			GRAPH_Y_GAP = 8;
		} else if (maxRange - minRange < 40) {
			GRAPH_Y_GAP = 10;
		}
	}

	const { max, min } = parseGraphData({ data, RANGE_GAP: GRAPH_Y_GAP });
	const gridYValues = generateRange(min, max, GRAPH_Y_GAP);
	const isMobile = useIsMobile();

	return (
		<BarChart
			data={data}
			axisBottom={{
				renderTick: YAxis,
			}}
			maxValue={max}
			minValue={min}
			colors={[BAR_GRAPH_COLOR]}
			gridYValues={gridYValues}
			tooltip={props => {
				return <GraphTooltip tooltipProps={props} metric={metric} />;
			}}
			layers={['grid', 'axes', 'bars', BarFallbackWithValue({ value: 'Nil', isMobile: isMobile }), 'markers']}
			axisLeft={{
				tickSize: 0,
				renderTick: ({ x, y, value }) => {
					if (Array.isArray(gridYValues)) {
						if (gridYValues.includes(value))
							return (
								<g key={value} transform={`translate(${x},${y})`}>
									<text x={-48} y={0} className="fill-gray-500 text-xs font-normal" dy=".32rem">
										{labelParser(value, metric.unit)}
									</text>
								</g>
							);
						return <g></g>;
					}
					return (
						<g key={value} transform={`translate(${x},${y})`}>
							<text x={-48} y={0} className="fill-gray-500 text-xs font-normal" dy=".32rem">
								{labelParser(value, metric.unit)}
							</text>
						</g>
					);
				},
			}}
		/>
	);
}
