'use client';

import { AlertTriangleIcon } from 'lucide-react';

import { useSmallWeatherFactsData } from '@/api/weather';
import { useAppStore } from '@/lib/store';
import { SmallWeatherFacts, WeatherDeviceType } from '@/types/constants';

import { FactsCardBottomSkeleton } from './FactsCardBottomSkeleton';
import { METRIC_CONSTANTS } from '../WeatherMap/utils';
import { ClientErrorBoundary } from '../ClientErrorBoundary';

const tmetric = METRIC_CONSTANTS.temperature;
const rmetric = METRIC_CONSTANTS.rain_accumulation;

export function MessageTemplate({ message, data }: { message: string; data: (string | number)[] }) {
	let boldIndex = 0;
	const boldValues = data.map(value => <b key={boldIndex++}>{value}</b>);
	// @ts-ignore
	const parsedMessage = message.split('%s').reduce((prev, current, index) => {
		return index < data.length ? [...prev, current, boldValues[index]] : [...prev, current];
	}, []);

	return <p className="text-base font-light text-secondary">{parsedMessage}</p>;
}

const factsData = [
	// {
	// 	id: SmallWeatherFacts.AVERAGE_TEMPERATURE_MIN,
	// 	message: `Today’s minimum temperature in %s is %s${metric.unit}, normally it records %s${metric.unit} around this time of the year `,
	// 	sibling: SmallWeatherFacts.LOCALITY_MIN_TEMPERATURE,
	// 	title: 'Lowest Temperature',
	// },
	{
		id: SmallWeatherFacts.AVERAGE_TEMPERATURE_MAX,
		message: `Today’s maximum temperature in %s is %s${tmetric.unitPrefix}${tmetric.unit}, normally it records %s${tmetric.unitPrefix}${tmetric.unit} around this time of the year `,
		sibling: SmallWeatherFacts.LOCALITY_MAX_TEMPERATURE,
		title: 'Temperature',
		parser: tmetric.parser,
		deviceType: [WeatherDeviceType.ZWS],
	},
	{
		id: SmallWeatherFacts.AVERAGE_RAIN_ACCUMULATION,
		message: `%s recorded %s${rmetric.unitPrefix}${rmetric.unit} rainfall in this month so far, normally it records around %s${rmetric.unitPrefix}${rmetric.unit} rainfall`,
		sibling: SmallWeatherFacts.LOCALITY_RAIN_ACCUMULATION,
		title: 'Rainfall',
		parser: rmetric.parser,
		deviceType: [WeatherDeviceType.ZWS, WeatherDeviceType.RAIN_GAUGE],
	},
	{
		id: SmallWeatherFacts.HIGHEST_RAIN_ACCUMULATION,
		message: `On %s, %s recorded the single day all-time high rainfall of  %s${rmetric.unitPrefix}${rmetric.unit}`,
		sibling: SmallWeatherFacts.HIGHEST_RAIN_ACCUMULATION,
		title: 'Extreme rainfall event',
		parser: rmetric.parser,
		deviceType: [WeatherDeviceType.RAIN_GAUGE],
	},
];

export function FactsCard() {
	const locationSelectedCity = useAppStore(state => state.locationSelectedCity);
	const locationSelectedLocality = useAppStore(state => state.locationSelectedLocality);
	const selectedCityData = useAppStore(state => state.selectedCityData);
	const selectedLocalityData = useAppStore(state => state.selectedLocalityData);

	let availableFacts = 0;

	const { data, isLoading, status } = useSmallWeatherFactsData({
		city_id: locationSelectedCity,
		locality_id: locationSelectedLocality,
	});

	return (
		<div className="w-full overflow-hidden rounded-2xl border border-gray-200 bg-white md:hidden lg:block lg:w-1/3">
			<h3 className="flex h-12 items-center px-4 py-8 text-2xl font-semibold text-primary md:h-16 md:px-6 md:py-10 md:text-2xl">
				Weather facts
			</h3>
			<div className="flex h-[calc(100%_-_4rem)] flex-col justify-between gap-8 p-4 pt-0 md:h-[calc(100%_-_6rem)] md:px-6">
				{(isLoading || status === 'pending') && <FactsCardBottomSkeleton />}
				<ClientErrorBoundary fallback={<FactsCardBottomErrorCard />}>
					{data && selectedLocalityData && (
						<div className="no-scrollbar flex w-full flex-col gap-6 overflow-y-auto">
							{factsData.map(fact => {
								const factData = data.weather_facts.find(f => f.label_key === fact.id);
								const siblingData = data.weather_facts.find(f => f.label_key === fact.sibling);

								if (!factData || !siblingData || !selectedCityData) return null;
								if (!fact.deviceType.includes(selectedLocalityData.device_type)) return null;

								if (siblingData.value === null || factData.value === null) return null;

								const siblingValue =
									fact.id === SmallWeatherFacts.HIGHEST_RAIN_ACCUMULATION
										? siblingData.facts_date
										: (fact.parser(Number(siblingData.value)) ?? 'N/A');
								const factValue = fact.parser(Number(factData.value)) ?? 'N/A';

								if (
									fact.id === SmallWeatherFacts.HIGHEST_RAIN_ACCUMULATION &&
									(!selectedCityData || !selectedCityData.city_name)
								) {
									console.warn('City name not found');
									return null;
								}

								const templateData =
									fact.id === SmallWeatherFacts.HIGHEST_RAIN_ACCUMULATION
										? [siblingValue, selectedCityData.city_name, factValue]
										: [selectedLocalityData.locality_name, siblingValue, factValue];

								availableFacts += 1;

								return (
									<div key={factData.label_key + selectedLocalityData.locality_id}>
										<h4 className="text-lg font-medium text-primary">{fact.title}</h4>
										<MessageTemplate message={fact.message} data={templateData} />
									</div>
								);
							})}
						</div>
					)}
				</ClientErrorBoundary>
				{locationSelectedLocality && !isLoading && availableFacts === 0 && <FactsCardBottomErrorCard />}
			</div>
		</div>
	);
}

function FactsCardBottomErrorCard() {
	return (
		<div className="flex h-full flex-col items-center justify-center text-center">
			<AlertTriangleIcon size={28} className="fill-red-500 stroke-white" />
			<h1 className="text-xs font-medium md:text-base">Sorry data temporarily unavailable, will be back soon</h1>
		</div>
	);
}
