'use client';

import Lottie from 'react-lottie';

import IndiaLottieAnimation from './india_zws.json';

export function IndiaZwsAnimation() {
	return (
		<Lottie
			options={{
				loop: true,
				autoplay: true,
				animationData: IndiaLottieAnimation,

				rendererSettings: {
					progressiveLoad: true,
				},
			}}
			style={{
				cursor: 'default',
			}}
			isClickToPauseDisabled
			ariaLabel="About Us Section"
		/>
	);
}
