import { AlertTriangleIcon } from 'lucide-react';

import { Alert, AlertDescription, AlertTitle } from '@/ui/alert';
import { Skeleton } from '@/ui/skeleton';

export function MapErrorScreen() {
	return (
		<div
			className="container mt-4 w-full px-2 sm:container"
			style={{
				// TODO: here adjust 32rem with map height
				maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 28rem, rgba(0, 0, 0, 0))',
			}}
		>
			<div className="container relative flex size-full h-[36rem] items-center justify-center rounded-xl border border-gray-300 bg-gray-200 sm:h-[52rem]">
				<Alert title="Error" variant="destructive">
					<AlertTriangleIcon name="warning" />
					<AlertTitle>Heads up!</AlertTitle>
					<AlertDescription>There was an error loading the map. Please try again later.</AlertDescription>
				</Alert>
			</div>
		</div>
	);
}
