'use client';

import { useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { useCityWeatherData } from '@/api/weather';
import { useAppStore } from '@/lib/store';
import { updateLocationUrl, weatherDecodeLocation } from '@/lib/utils';
import { BASE_PATH } from '@/lib/constants';
import { useTracker } from '@/lib/tracking/useTracker';

import { LocalitySelectionHolder } from './LocationSelectorHolder';

export function LocalitySelection() {
	const pathname = usePathname();
	const router = useRouter();
	const { trackEvent } = useTracker();

	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const parsedWeatherData = useAppStore(state => state.parsedWeatherData);
	const selectedCity = useAppStore(state => state.locationSelectedCity);
	const setSelectedCity = useAppStore(state => state.setLocationSelectedCity);
	const selectedLocality = useAppStore(state => state.locationSelectedLocality);
	const setSelectedLocality = useAppStore(state => state.setLocationSelectedLocality);

	const setCityWeatherData = useAppStore(state => state.setCityWeatherData);
	const setIsUserInteractingMap = useAppStore(state => state.setIsUserInteractingMap);
	const { data: cityWeatherData } = useCityWeatherData(selectedCity);

	const setSelectedCityData = useAppStore(state => state.setSelectedCityData);
	const setSelectedLocalityData = useAppStore(state => state.setSelectedLocalityData);

	const locationChangeHandler = (cityId: string, localityId: string, source: 'auto' | 'manual') => {
		setSelectedCity(cityId);
		setSelectedLocality(localityId);

		if (source === 'manual') {
			setIsUserInteractingMap(false);
			updateLocationUrl({
				cityId: cityId,
				localityId: localityId,
				parsedWeatherData: parsedWeatherData,
			});
		}
	};

	const onLocalityChange = (localityId: string) => {
		if (!selectedCity) {
			return console.warn(' DEBUG :: onLocalityChange : City not selected');
		}

		locationChangeHandler(selectedCity, localityId, 'manual');

		trackEvent({
			event_type: 'IMPRESSION',
			component: 'LOCALITY_SELECT',
			ui_source: 'LANDING_PAGE',
			meta_data: {
				locality_id: selectedLocality,
				city_id: selectedCity,
			},
		});
	};

	const onCityChange = (cityId: string) => {
		const selectedCityData = parsedWeatherData.data[cityId];
		const defaultLocalityId = selectedCityData.deafault_locality_id;

		locationChangeHandler(cityId, defaultLocalityId, 'manual');

		trackEvent({
			event_type: 'IMPRESSION',
			component: 'CITY_SELECT',
			ui_source: 'LANDING_PAGE',
			meta_data: {
				city_id: selectedCity,
				locality_id: defaultLocalityId,
			},
		});
	};

	useEffect(() => {
		if (!selectedCity || !cityWeatherData) {
			return console.warn(' DEBUG :: useEffect : City not selected ', { selectedCity, cityWeatherData });
		}

		const _selectedCityData = parsedWeatherData.data[selectedCity];

		if (_selectedCityData) {
			setSelectedCityData(_selectedCityData);
			setCityWeatherData(cityWeatherData);
		} else {
			console.warn(' DEBUG :: City not found in parsed data');
		}

		/**
		 * DEV: No need to add selectedCity in the dependency array
		 * as it will auto change and reflect in the cityWeatherData
		 */
	}, [cityWeatherData]);

	useEffect(() => {
		if (selectedLocality && selectedCity && cityWeatherData) {
			const _selectedLocality = cityWeatherData.data[selectedLocality];

			if (!_selectedLocality) {
				return;
			}

			const _selectedLocalityData = _selectedLocality.locality_detail;

			if (_selectedLocalityData) {
				setSelectedLocalityData(_selectedLocalityData);
			}
		}
	}, [selectedLocality, selectedCity, cityWeatherData]);

	useEffect(() => {
		if (!parsedWeatherData || !isFirstLoad) return;

		if (pathname === BASE_PATH) {
			const lastCityId = localStorage.getItem('lastCityId');
			const lastLocalityId = localStorage.getItem('lastLocalityId');

			if (lastCityId && lastLocalityId) {
				trackEvent({
					event_type: 'PAGE_LAUNCH',
					component: 'APP_LAYOUT',
					ui_source: 'SERVER_DEFAULT',
					meta_data: {
						locality_id: lastLocalityId,
						city_id: lastCityId,
					},
				});

				locationChangeHandler(lastCityId, lastLocalityId, 'auto');
				return;
			}

			const { default_city_detail } = parsedWeatherData.root;

			if (!default_city_detail) {
				return;
			}

			const { city_id, locality_id } = default_city_detail;

			trackEvent({
				event_type: 'PAGE_LAUNCH',
				component: 'APP_LAYOUT',
				ui_source: 'SERVER_DEFAULT',
				meta_data: {
					locality_id: locality_id,
					city_id: city_id,
				},
			});

			locationChangeHandler(city_id, locality_id, 'auto');
		} else {
			// TODO / SECURITY: ADD ERROR HANDLING when url is bad + cb domain
			const { city_id, locality_id, isValid } = weatherDecodeLocation(pathname);

			if (!isValid || !city_id || !locality_id) {
				return router.push(BASE_PATH);
			}

			trackEvent({
				event_type: 'PAGE_LAUNCH',
				component: 'APP_LAYOUT',
				ui_source: 'FROM_URL',
				meta_data: {
					locality_id: locality_id,
					city_id: city_id,
				},
			});

			locationChangeHandler(city_id, locality_id, 'auto');
		}

		setIsFirstLoad(false);
	}, [parsedWeatherData, isFirstLoad, pathname]);

	return (
		<div className={'flex w-full flex-col gap-4 text-gray-800 sm:flex-row md:w-fit'}>
			<LocalitySelectionHolder
				selectedCity={selectedCity}
				setSelectedCity={onCityChange}
				selectedLocality={selectedLocality}
				setSelectedLocality={onLocalityChange}
				className="md:shadow-md"
			/>
		</div>
	);
}
