import { useEffect, useRef, useState } from 'react';

/**
 * @desc Made compatible with {GeolocationPositionError} and {PositionError} cause
 * PositionError been renamed to GeolocationPositionError in typescript 4.1.x and making
 * own compatible interface is most easiest way to avoid errors.
 */
export interface IGeolocationPositionError {
	readonly code: number;
	readonly message: string;
	readonly PERMISSION_DENIED: number;
	readonly POSITION_UNAVAILABLE: number;
	readonly TIMEOUT: number;
}

export interface GeoLocationSensorState {
	loading: boolean;
	accuracy: number | null;
	altitude: number | null;
	altitudeAccuracy: number | null;
	heading: number | null;
	latitude: number | null;
	longitude: number | null;
	speed: number | null;
	timestamp: number | null;
	error?: Error | IGeolocationPositionError;
}

interface PositionOptions {
	enableHighAccuracy?: boolean;
	maximumAge?: number;
	timeout?: number;
}

const useGeolocation = (enabled: boolean = true, options?: PositionOptions): GeoLocationSensorState => {
	const [state, setState] = useState<GeoLocationSensorState>({
		loading: false,
		accuracy: null,
		altitude: null,
		altitudeAccuracy: null,
		heading: null,
		latitude: null,
		longitude: null,
		speed: null,
		timestamp: Date.now(),
	});
	const mounted = useRef<boolean>(false);
	const watchId = useRef<any>(null);

	const onEvent = (event: any) => {
		setState({
			loading: false,
			accuracy: event.coords.accuracy,
			altitude: event.coords.altitude,
			altitudeAccuracy: event.coords.altitudeAccuracy,
			heading: event.coords.heading,
			latitude: event.coords.latitude,
			longitude: event.coords.longitude,
			speed: event.coords.speed,
			timestamp: event.timestamp,
		});
	};
	const onEventError = (error: IGeolocationPositionError) => {
		mounted.current && setState(oldState => ({ ...oldState, loading: false, error }));
	};

	useEffect(() => {
		if (!enabled) return;
		mounted.current = true;
		setState(oldState => ({ ...oldState, loading: true }));

		navigator.geolocation.getCurrentPosition(onEvent, onEventError, options);
		watchId.current = navigator.geolocation.watchPosition(onEvent, onEventError, options);

		return () => {
			mounted.current = false;
			navigator.geolocation.clearWatch(watchId.current);
		};
	}, [enabled]);

	return state;
};

export default useGeolocation;
