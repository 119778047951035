'use client';

import { Skeleton } from '@/ui/skeleton';

export function MapSkeleton() {
	return (
		<div
			className="container mt-4 w-full px-2 sm:container"
			style={{
				// TODO: here adjust 32rem with map height
				maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 28rem, rgba(0, 0, 0, 0))',
			}}
		>
			<Skeleton className="relative size-full h-[36rem] rounded-xl border border-gray-300 sm:h-[52rem]"></Skeleton>
		</div>
	);
}
