import { Skeleton } from '@/ui/skeleton';

export function FactsCardBottomSkeleton() {
	return (
		<div className="flex flex-col gap-4">
			<Skeleton className="size-full min-h-24" />
			<Skeleton className="size-full min-h-24" />
		</div>
	);
}
