'use client';

import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '@/lib/utils';

interface ProgressProps extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
	indicatorStyles?: React.CSSProperties;
	indicatorClassName?: string;
}

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ProgressProps>(
	({ className, value, indicatorStyles, indicatorClassName, ...props }, ref) => (
		<ProgressPrimitive.Root
			ref={ref}
			className={cn('relative h-4 w-full overflow-hidden rounded-full', className)}
			{...props}
		>
			<ProgressPrimitive.Indicator
				className={cn('size-full flex-1 rounded-full bg-white/80 transition-all', indicatorClassName)}
				style={{
					transform: `translateX(-${100 - (value || 0)}%)`,
					...indicatorStyles,
				}}
			/>
		</ProgressPrimitive.Root>
	),
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
