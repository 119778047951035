import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { Marker } from '@adamscybot/react-leaflet-component-marker';
import { useMutation } from '@tanstack/react-query';

import { DEFAULT_API_ERROR_TITLE } from '@/lib/constants';
import useGeolocation from '@/hooks/useGeolocation';
import { useAppStore } from '@/lib/store';
import { getLocationFromCoordinates } from '@/api/weather';
import { useToast } from '@/ui/use-toast';
import { useTracker } from '@/lib/tracking/useTracker';
import { useIsMobile } from '@/hooks/useIsMobile';

import { setMapView } from './MapMarkerOverlay';

export function MapLiveGeoLocation() {
	const map = useMap();
	const { toast } = useToast();
	const mapLiveLocationEnabled = useAppStore(state => state.mapLiveLocationEnabled);
	const setMapLiveLocationEnabled = useAppStore(state => state.setMapLiveLocationEnabled);
	const setLocationSelectedCity = useAppStore(state => state.setLocationSelectedCity);
	const setLocationSelectedLocality = useAppStore(state => state.setLocationSelectedLocality);
	const locationSelectedCity = useAppStore(state => state.locationSelectedCity);
	const status = useGeolocation(mapLiveLocationEnabled === 'loading', {
		timeout: 30 * 1000,
		maximumAge: 60 * 1000,
		enableHighAccuracy: false,
	});
	const { trackEvent } = useTracker();
	const isMobile = useIsMobile();

	const mutation = useMutation({
		mutationFn: getLocationFromCoordinates,
		onError: error => {
			trackEvent({
				event_type: 'ERROR_BOUNDARY',
				component: 'LOCATE_ME',
				ui_source: 'API',
				meta_data: {
					error: {
						message: error.message,
					},
				},
			});

			toast({
				variant: 'destructive',
				title: DEFAULT_API_ERROR_TITLE,
				description: error.message,
			});
		},
	});

	useEffect(() => {
		setMapLiveLocationEnabled('idle');
	}, [locationSelectedCity]);

	useEffect(() => {
		const data = mutation.data;

		if (!data) return;

		if (data.drop_location_found) {
			trackEvent({
				event_type: 'IMPRESSION',
				component: 'LOCATE_ME',
				ui_source: 'MAP',
				meta_data: {
					city_id: data.user_location.city_id,
					locality_id: data.user_location.locality_id,
					user_latitude: status.latitude,
					user_longitude: status.longitude,
					accuracy: status.accuracy,
					location_found: true,
				},
			});
			setLocationSelectedCity(data.user_location.city_id);
			setLocationSelectedLocality(data.user_location.locality_id);

			localStorage.setItem('lastCityId', data.user_location.city_id);
			localStorage.setItem('lastLocalityId', data.user_location.locality_id);
		} else {
			trackEvent({
				event_type: 'IMPRESSION',
				component: 'LOCATE_ME',
				ui_source: 'MAP',
				meta_data: {
					location_found: false,
					user_latitude: status.latitude,
					user_longitude: status.longitude,
					accuracy: status.accuracy,
				},
			});
			// setLocationSelectedCity(data.default_location.city_id);
			// setLocationSelectedLocality(data.default_location.locality_id);
			setMapLiveLocationEnabled('not-found');
		}
	}, [mutation.data]);

	useEffect(() => {
		if (status.loading) return;

		if (status.error) {
			setMapLiveLocationEnabled('disabled');
		}

		if (status.latitude !== null && status.longitude !== null) {
			setMapView({
				latitude: status.latitude,
				longitude: status.longitude,
				map,
				mode: 'fly',
				isMobile: isMobile,
			});

			map.once('moveend', e => {
				if (status.latitude && status.longitude) {
					mutation.mutate({
						latitude: status.latitude,
						longitude: status.longitude,
					});
					setMapLiveLocationEnabled('success');
				}
			});
		}

		if (status.error) {
			setMapLiveLocationEnabled('error');
			toast({
				variant: 'destructive',
				title: 'Unable to fetch live location',
				description: status.error.message,
			});
			trackEvent({
				event_type: 'ERROR_BOUNDARY',
				component: 'LOCATE_ME',
				ui_source: 'MAP',
				meta_data: {
					error: {
						user_latitude: status.latitude,
						user_longitude: status.longitude,
						accuracy: status.accuracy,
						location_found: false,
						message: status.error.message,
					},
				},
			});
		}
	}, [status, map]);

	if (status.latitude === null || status.longitude === null) {
		return null;
	}

	return (
		<Marker
			position={[status.latitude, status.longitude]}
			iconComponentLayout="fit-content"
			icon={() => {
				return (
					<div className="relative z-20 flex size-8 items-center justify-center rounded-full">
						<div className="absolute size-6 animate-ping rounded-full bg-blue-500 opacity-75"></div>
						<div className="absolute size-6 rounded-full border-4 border-white/90 bg-blue-500"></div>
					</div>
				);
			}}
		/>
	);
}
