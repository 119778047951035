import { BarSvgProps, ResponsiveBar } from '@nivo/bar';
import { FC } from 'react';

import { useIsMobile } from '@/hooks/useIsMobile';

import { CustomBarComponent } from '../CustomBarComponent';

export interface BarChartProps extends BarSvgProps<any> {
	data: GraphDataProps[];
}

const BarChart: FC<Omit<BarChartProps, 'height' | 'width'>> = ({ data, ...otherProps }) => {
	const isMobile = useIsMobile();

	return (
		<ResponsiveBar
			data={data}
			margin={
				isMobile ? { top: 25, right: 0, bottom: 40, left: 54 } : { top: 28, right: 32, bottom: 72, left: 52 }
			}
			indexBy={'x'}
			keys={['y']}
			groupMode="grouped"
			animate={true}
			barComponent={CustomBarComponent}
			borderRadius={isMobile ? 3 : 4}
			enableGridX={false}
			padding={isMobile ? 0.5 : 0.7}
			layout="vertical"
			{...otherProps}
		/>
	);
};

export default BarChart;
