import { LatLng } from 'leaflet';
import { Marker } from '@adamscybot/react-leaflet-component-marker';
import React from 'react';
import { useMap } from 'react-leaflet';

import { useAppStore } from '@/lib/store';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/ui/tooltip';
import { Card, CardContent } from '@/ui/card';
import { Badge } from '@/ui/badge';
import { useTracker } from '@/lib/tracking/useTracker';
import { useIsMobile } from '@/hooks/useIsMobile';
import { updateLocationUrl } from '@/lib/utils';

import { setMapView } from './MapMarkerOverlay';

export function CityLayerMarkers() {
	const map = useMap();
	const parsedWeatherData = useAppStore(state => state.parsedWeatherData);
	const setSelectedCity = useAppStore(state => state.setLocationSelectedCity);
	const setSelectedLocality = useAppStore(state => state.setLocationSelectedLocality);
	const { trackEvent } = useTracker();
	const isMobile = useIsMobile();

	const onCityChange = (cityId: string) => {
		const defaultLocalityId = parsedWeatherData.data[cityId].deafault_locality_id;
		const { city_latitude, city_longitude } = parsedWeatherData.data[cityId];

		setMapView({
			latitude: city_latitude,
			longitude: city_longitude,
			map,
			mode: 'fly',
			shouldZoom: true,
			isMobile: isMobile,
		});

		trackEvent({
			event_type: 'CLICK',
			component: 'MAP_ICON_CITY',
			ui_source: 'MAP',
			meta_data: {
				city_id: cityId,
				locality_id: defaultLocalityId,
				latitude: city_latitude,
				longitude: city_longitude,
			},
		});

		map.once('moveend', () => {
			setSelectedCity(cityId);
			const selectedCityData = parsedWeatherData.data[cityId];

			if (selectedCityData) {
				setSelectedLocality(defaultLocalityId);
				updateLocationUrl({
					cityId: cityId,
					localityId: defaultLocalityId,
					parsedWeatherData: parsedWeatherData,
				});
			}
		});
	};

	if (!parsedWeatherData) {
		return null;
	}

	return (
		<>
			{Object.values(parsedWeatherData.data).map((city, index) => {
				return (
					<Marker
						riseOnHover
						eventHandlers={{
							click: () => onCityChange(city.city_id),
						}}
						iconComponentLayout="fit-content"
						key={`${index}_${city.city_name}`}
						position={new LatLng(city.city_latitude, city.city_longitude)}
						icon={
							<Tooltip>
								<TooltipContent className="rounded-lg p-0">
									<Card className="min-w-36 max-w-48 rounded-lg bg-gray-700 p-0 font-normal text-white">
										<CardContent className="m-0 flex flex-col gap-1 p-3 py-2 shadow-lg animate-in slide-in-from-top-10">
											{city.city_name}
											{/* <div className="flex max-w-48 gap-1 font-light text-gray-200">
												{Object.keys(city.localities).length}{' '}
												{Object.keys(city.localities).length > 1 ? 'Localities' : 'Locality'}
											</div> */}
										</CardContent>
									</Card>
									<TooltipArrow className="-mt-1 h-2 w-4 rounded-sm fill-gray-700" offset={12} />
								</TooltipContent>
								<TooltipTrigger>
									<Badge
										className={`whitespace-nowrap border border-white bg-gray-700 text-sm font-light text-white animate-in fade-in-0 slide-in-from-left-5 hover:bg-slate-800`}
									>
										{city.city_name}
									</Badge>
								</TooltipTrigger>
							</Tooltip>
						}
					/>
				);
			})}
		</>
	);
}
