import { useState } from 'react';
import { TooltipPortal } from '@radix-ui/react-tooltip';
import { useMapEvents } from 'react-leaflet';

import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/ui/tooltip';
import { Card, CardContent, CardHeader } from '@/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/popover';

interface MapMarkerTooltipProps {
	cardHeader: React.ReactNode;
	cardContent: React.ReactNode;
	tooltipTrigger: React.ReactNode;
	keepOpen?: boolean;
	isMobile?: boolean;
}

function MapMarkerTooltipMobile({ cardHeader, cardContent, tooltipTrigger }: MapMarkerTooltipProps) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	useMapEvents({
		dragstart: () => {
			setIsPopoverOpen(false);
		},
	});

	return (
		<Popover
			// {...(keepOpen && { open: true })}
			onOpenChange={setIsPopoverOpen}
			open={isPopoverOpen}
		>
			<PopoverTrigger>{tooltipTrigger}</PopoverTrigger>
			<PopoverContent
				className="max-w-52 rounded-xl p-0"
				align="center"
				// onPointerDownCapture={() => setIsPopoverOpen(false)}
			>
				<Card className="rounded-xl border border-white/80 bg-gray-600 p-0 text-white">
					<CardHeader className="mx-2 px-3 py-4 pb-0">{cardHeader}</CardHeader>
					<CardContent className="m-0 flex flex-col gap-1 p-4 py-3 shadow-lg animate-in slide-in-from-top-10">
						{cardContent}
					</CardContent>
				</Card>
			</PopoverContent>
		</Popover>
	);
}

function MapMarkerDesktopTooltip({ cardHeader, cardContent, tooltipTrigger, keepOpen }: MapMarkerTooltipProps) {
	return (
		<Tooltip {...(keepOpen && { open: true })}>
			<TooltipPortal>
				<TooltipContent asChild>
					<Card className="min-w-48 max-w-52 overflow-hidden rounded-2xl border border-white/80 bg-gray-600 p-0 text-white">
						<CardHeader className="mx-3 p-2">{cardHeader}</CardHeader>
						<CardContent className="m-0 flex flex-col gap-1 p-4 pt-0 shadow-lg animate-in slide-in-from-top-10">
							{cardContent}
						</CardContent>
						<TooltipArrow className="size-4 -translate-y-1/2 rotate-45 rounded-ee-sm border-b border-r border-white bg-gray-600 fill-gray-600" />
					</Card>
				</TooltipContent>
			</TooltipPortal>
			<TooltipTrigger aria-label="More Information">{tooltipTrigger}</TooltipTrigger>
		</Tooltip>
	);
}

export function MapMarkerTooltip({
	cardHeader,
	cardContent,
	tooltipTrigger,
	keepOpen,
	isMobile,
}: MapMarkerTooltipProps) {
	const Component = isMobile ? MapMarkerTooltipMobile : MapMarkerDesktopTooltip;

	return (
		<Component
			cardHeader={cardHeader}
			cardContent={cardContent}
			tooltipTrigger={tooltipTrigger}
			keepOpen={keepOpen}
			isMobile={isMobile}
		/>
	);
}
