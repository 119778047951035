import { LatLng } from 'leaflet';
import { Marker } from '@adamscybot/react-leaflet-component-marker';
import React, { useCallback, useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import L, { ZoomPanOptions } from 'leaflet';

import { useAppStore } from '@/lib/store';
import { Badge } from '@/ui/badge';
import { lexendFont, updateLocationUrl } from '@/lib/utils';
import { WeatherDeviceType, WeatherModes } from '@/types/constants';
import { Separator } from '@/ui/separator';
import { useTracker } from '@/lib/tracking/useTracker';

import { getMapControlData } from './utils';
import { HumidityIcon, RainIcon, TemperatureIcon, WindIcon } from './MapIcons';
import { MapTooltipParser } from './MapValueParser';
import { IconBadgeProps } from './MapIcons/utils';
import { CityLayerMarkers } from './CityLayerMarkers';
import { DownIcon } from './MapIcons/DownIcon';
import { MapMarkerTooltip } from './MapMarkerTooltip';

const MarkerIconCustom = ({
	data,
	mapSelectedMode,
	selectedMarker,
	selectedMarkerValue,
	developerOptions,
	isMobile,
}: {
	data: ParsedLocalityWeatherData;
	mapSelectedMode: WeatherModes | null;
	selectedMarker?: string;
	selectedMarkerValue?: string;
	developerOptions: DeveloperOptions;
	isMobile: boolean;
}) => {
	const mapControlData = getMapControlData(data, [WeatherModes.RAIN_ACCUMULATION]);

	let value: number | null = null;
	let BadgeIcon: React.FC<IconBadgeProps>;
	const isLocalitySelected = data.locality_detail.locality_id === selectedMarker;
	const { humidity, temperature, rain_intensity_level, wind_speed_level } = data.weather_metrics;

	switch (mapSelectedMode) {
		case WeatherModes.TEMPERATURE:
			value = temperature;
			BadgeIcon = TemperatureIcon;
			break;
		case WeatherModes.RAIN_INTENSITY:
			value = rain_intensity_level;
			BadgeIcon = RainIcon;
			break;
		case WeatherModes.HUMIDITY:
			value = humidity;
			BadgeIcon = HumidityIcon;
			break;
		case WeatherModes.WIND:
			value = wind_speed_level;
			BadgeIcon = WindIcon;
			break;
		default:
			value = null;
			BadgeIcon = DownIcon;
			break;
	}

	const CardTooltipTrigger = () => {
		return <BadgeIcon value={value} showUnavailable={developerOptions.showDownDevices || isLocalitySelected} />;
	};

	const CardTooltipContent = () => {
		const isDeviceDowntime =
			data && data.weather_metrics
				? Object.values(data.weather_metrics)
						.filter(value => value !== 0)
						.every(value => value === null)
				: false;

		const DeviceDetails = () => (
			<div className="flex flex-col gap-1 font-light">
				<span>Latitude: {data.locality_detail.latitude}</span>
				<span>Longitude: {data.locality_detail.longitude}</span>
				<span>Device Type: {data.locality_detail.device_type}</span>
			</div>
		);

		return (
			<div className="flex flex-col gap-2">
				{(data.locality_detail.device_type === WeatherDeviceType.UNKNOWN || isDeviceDowntime) && (
					<div className="ml-1 max-w-48 text-sm font-light text-gray-200 md:text-sm">
						Sorry, data is temporarily unavailable in this area. We will be back soon.
					</div>
				)}
				{!(data.locality_detail.device_type === WeatherDeviceType.UNKNOWN || isDeviceDowntime) &&
					mapControlData.map((control, index) => {
						if (!control) return null;

						if (!control.deviceTypes.includes(data.locality_detail.device_type)) {
							return null;
						}

						return (
							<div key={index} className="flex items-center">
								<control.icon size={18} />
								<MapTooltipParser control={control} isDeviceDowntime={false} />
							</div>
						);
					})}
				{developerOptions.showDeviceDetails && (
					<>
						<Separator className="mt-1 opacity-70" orientation="horizontal" />
						<DeviceDetails />
					</>
				)}
			</div>
		);
	};

	const CardTooltipHeader = () => {
		return <h4 className="text-sm font-light md:text-base">{data.locality_detail.locality_name}</h4>;
	};

	if (isLocalitySelected) {
		return (
			<div className="relative" tabIndex={-1}>
				<Badge
					className={`absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-7 whitespace-nowrap border border-white bg-slate-800 px-3 py-1 text-[13px] font-normal text-white shadow-lg hover:bg-slate-700 md:text-sm ${lexendFont.className}`}
				>
					{selectedMarkerValue}
					<div className="absolute -bottom-1/2 left-1/2 z-20 h-4 w-2 -translate-x-1 translate-y-0.5 rounded-full rounded-t-none border border-t-0 border-white bg-slate-800 md:-translate-x-1 md:translate-y-0.5" />
				</Badge>
				<MapMarkerTooltip
					isMobile={isMobile}
					keepOpen={developerOptions.keepSelectedPinOpen}
					cardContent={<CardTooltipContent />}
					cardHeader={<CardTooltipHeader />}
					tooltipTrigger={<CardTooltipTrigger />}
				/>
			</div>
		);
	}

	return (
		<MapMarkerTooltip
			cardContent={<CardTooltipContent />}
			cardHeader={<CardTooltipHeader />}
			tooltipTrigger={<CardTooltipTrigger />}
		/>
	);
};

export function MapMarkerOverlay() {
	const [isLargeLayout, setIsLargeLayout] = useState(false);
	const developerOptions = useAppStore(state => state.developerOptions);

	const selectedLocalityData = useAppStore(state => state.selectedLocalityData);
	const cityWeatherData = useAppStore(state => state.cityWeatherData);
	const mapSelectedMode = useAppStore(state => state.mapSelectedMode);
	const selectedCity = useAppStore(state => state.locationSelectedCity);
	const parsedWeatherData = useAppStore(state => state.parsedWeatherData);
	const setSelectedLocality = useAppStore(state => state.setLocationSelectedLocality);
	const setIsUserInteractingMap = useAppStore(state => state.setIsUserInteractingMap);
	const isUserInteractingMap = useAppStore(state => state.isUserInteractingMap);
	const { trackEvent } = useTracker();

	const zoomHandler = useCallback(() => {
		if (map.getZoom() < 9) {
			setIsLargeLayout(true);
		} else {
			setIsLargeLayout(false);
		}
	}, []);

	const map = useMapEvents({
		zoomend: zoomHandler,
		dragend: () => {
			setIsUserInteractingMap(true);
		},
	});

	const isMobile = map.getContainer().id === 'map-container__mobile';

	useEffect(() => {
		if (selectedLocalityData) {
			if (map) {
				setMapView({
					latitude: selectedLocalityData.latitude,
					longitude: selectedLocalityData.longitude,
					map,
					mode: 'view',
					shouldZoom: !developerOptions.disableZoomOnSelect,
					isMobile,
				});
			}
		}
	}, [developerOptions.disableZoomOnSelect, isMobile, map, selectedLocalityData]);

	const onClickHandler = useCallback(
		(locality: WeatherLocality) => {
			setSelectedLocality(locality.locality_id);

			if (!selectedCity) {
				return console.warn(' DEBUG :: City not selected');
			}

			updateLocationUrl({
				cityId: selectedCity,
				localityId: locality.locality_id,
				parsedWeatherData: parsedWeatherData,
			});

			setIsUserInteractingMap(false);

			trackEvent({
				event_type: 'CLICK',
				component: 'MAP_ICON_LOCALITY',
				ui_source: 'MAP',
				meta_data: {
					locality_id: locality.locality_id,
					mode: mapSelectedMode,
				},
			});

			if (!isUserInteractingMap) {
				setMapView({
					latitude: locality.latitude,
					longitude: locality.longitude,
					map,
					mode: 'fly',
					shouldZoom: true,
					isMobile,
				});
			}
		},
		[
			isMobile,
			isUserInteractingMap,
			map,
			mapSelectedMode,
			parsedWeatherData,
			selectedCity,
			setIsUserInteractingMap,
			setSelectedLocality,
			trackEvent,
		],
	);

	if (isLargeLayout) {
		return <CityLayerMarkers />;
	}

	if (!cityWeatherData || !selectedLocalityData) return null;

	return (
		<>
			{Object.values(cityWeatherData.data).map((data, index) => {
				const isSelected = data.locality_detail.locality_id === selectedLocalityData.locality_id;

				return (
					<Marker
						eventHandlers={{
							click: () => onClickHandler(data.locality_detail),
						}}
						{...(isSelected && { zIndexOffset: 1000 })}
						iconComponentLayout="fit-content"
						key={`${index}_${data.locality_detail.locality_id}`}
						position={new LatLng(data.locality_detail.latitude, data.locality_detail.longitude)}
						icon={
							<MarkerIconCustom
								isMobile={isMobile}
								data={data}
								mapSelectedMode={mapSelectedMode}
								selectedMarker={selectedLocalityData?.locality_id}
								selectedMarkerValue={selectedLocalityData?.locality_name}
								developerOptions={developerOptions}
							/>
						}
					/>
				);
			})}
		</>
	);
}

interface SetMapViewProps {
	map: L.Map;
	latitude: number;
	longitude: number;
	zoom?: number;
	options?: ZoomPanOptions;
	mode: 'view' | 'fly';
	shouldZoom?: boolean;
	isMobile?: boolean;
}

export function setMapView({ latitude, longitude, map, zoom, options, mode, shouldZoom, isMobile }: SetMapViewProps) {
	zoom = (zoom ?? isMobile) ? 13 : 13;
	const absoluteShiftInLat = isMobile ? 0 : 0;
	const absoluteShiftInLng = isMobile ? 0 : 0;
	const position = new L.LatLng(latitude - absoluteShiftInLat, longitude);

	if (mode === 'view') {
		map.setView(position, shouldZoom ? zoom : map.getZoom(), options);
	} else if (mode === 'fly') {
		map.flyTo(position, shouldZoom ? zoom : map.getZoom(), options);
	}
}
