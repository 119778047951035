import React from 'react';
import { MoveUpIcon } from 'lucide-react';

import { useCityWeatherData } from '@/api/weather';
import { useCountdownLoop } from '@/hooks/useCountdownLoop';
import { Progress } from '@/ui/progress';
import { useAppStore } from '@/lib/store';
import { WeatherDeviceType, WeatherModes } from '@/types/constants';
import { useDelayedState } from '@/hooks/useDelayedState';
import { Skeleton } from '@/ui/skeleton';

import { MapLiveWeatherSkeleton } from './MapLiveWeatherSkeleton';
import { getFallbackValue, getMapControlData, getWindDirection } from './utils';
import { LiveWeatherParser } from './MapValueParser';

export function MapLiveWeather() {
	const locationSelectedCity = useAppStore(state => state.locationSelectedCity);
	const locationSelectedLocality = useAppStore(state => state.locationSelectedLocality);
	const selectedLocalityData = useAppStore(state => state.selectedLocalityData);
	const { data: cityWeatherData, isRefetching, dataUpdatedAt } = useCityWeatherData(locationSelectedCity);

	const localityWeatherData =
		cityWeatherData && locationSelectedLocality && cityWeatherData.data[locationSelectedLocality];
	const { isLoading } = useDelayedState(localityWeatherData, 300);

	// TODO: fix me
	if (!cityWeatherData || !locationSelectedLocality) return <MapLiveWeatherSkeleton />;

	const mapControlData = getMapControlData(cityWeatherData.data[locationSelectedLocality]);

	const isDeviceDowntime =
		cityWeatherData && cityWeatherData.data[locationSelectedLocality]
			? Object.values(cityWeatherData.data[locationSelectedLocality].weather_metrics)
					.filter(value => value !== 0)
					.every(value => value === null)
			: false;

	if (!selectedLocalityData || !localityWeatherData || selectedLocalityData.device_type === WeatherDeviceType.UNKNOWN)
		return null;

	return (
		<div className="container z-10 mt-3 min-w-80 max-w-full overflow-hidden rounded-xl animate-in fade-in-50 slide-in-from-bottom sm:absolute sm:right-0 sm:top-0 sm:mr-4 sm:mt-4 sm:slide-in-from-right md:min-w-0 md:max-w-72 md:slide-in-from-bottom-0">
			<div className="flex flex-col justify-between gap-2 rounded-t-xl bg-black/80 px-4 py-3 font-normal tracking-wider text-white sm:py-4 md:items-start md:backdrop-blur-sm">
				<div className="flex flex-col">
					<div className="flex items-center gap-2">
						<h2 className="text-base font-normal">Live weather</h2>
						<span className="-ml-1 -mt-2 size-2 animate-pulse rounded-full bg-red-600 duration-1000" />
					</div>
					<LiveWeatherStatus dataUpdatedAt={dataUpdatedAt} />
				</div>
				<div className="flex flex-col">
					<span className="text-base font-normal tracking-normal">{selectedLocalityData.locality_name}</span>
					{selectedLocalityData.device_type === WeatherDeviceType.RAIN_GAUGE && (
						<span className="text-xs font-light text-gray-300">We record only rain data in this area</span>
					)}
				</div>
			</div>
			<div className="grid grid-cols-2 gap-x-2 gap-y-6 rounded-b-xl bg-black/70 px-3 py-4 pb-6 text-white backdrop-blur-md sm:flex sm:flex-col sm:gap-6 md:gap-x-4 md:px-3 md:py-5 md:backdrop-blur-md">
				{localityWeatherData &&
					mapControlData.map(control => {
						if (control.deviceTypes.includes(selectedLocalityData.device_type)) {
							return (
								<div key={control.id} className="flex flex-col gap-2 px-0 sm:gap-6 sm:px-4 md:px-1">
									<div className="flex flex-row gap-1 sm:gap-2">
										<control.icon className="size-6 sm:size-8" />
										<div className="flex w-full flex-col gap-0 sm:gap-0">
											<span className="text-sm font-normal">{control.title}</span>
											{isLoading ? (
												<Skeleton className="mt-2 h-4 w-14 rounded-sm bg-neutral-500" />
											) : (
												<span className="text-lg font-normal animate-in fade-in-0">
													{control.id === WeatherModes.WIND &&
													cityWeatherData.data !== null ? (
														<WeatherDirectionTag
															data={localityWeatherData.weather_metrics}
															control={control}
															isDeviceDowntime={isDeviceDowntime}
														/>
													) : (
														<LiveWeatherParser
															control={control}
															className="text-sm font-light"
															isDeviceDowntime={isDeviceDowntime}
														/>
													)}
												</span>
											)}

											{isLoading ? (
												<Skeleton className="mt-2 h-1 w-full bg-neutral-500 opacity-70" />
											) : (
												<Progress
													aria-label={control.title}
													aria-valuenow={control.percentage}
													aria-valuemin={0}
													aria-valuemax={100}
													value={control.percentage}
													className="mt-1 h-1 bg-[#292929]"
													{...(control.id === WeatherModes.TEMPERATURE && {
														indicatorStyles: {
															backgroundImage: control.gradient?.toString(),
															transform: `translateX(0)`,
															clipPath: `inset(0 calc(100% - ${control.percentage}%) 0 0)`,
														},
													})}
												/>
											)}
										</div>
									</div>
								</div>
							);
						}
					})}
			</div>
		</div>
	);
}

function WeatherDirectionTag({
	data,
	control,
	isDeviceDowntime,
}: {
	data: LocalityWeatherDetail;
	control: MetriControl;
	isDeviceDowntime: boolean;
}) {
	const { isValid, value } = getFallbackValue(control.id as WeatherModes, control.value, isDeviceDowntime);

	return (
		<div className="flex items-baseline gap-1">
			<LiveWeatherParser
				control={control}
				isDeviceDowntime={isDeviceDowntime}
				className="text-xs font-light md:text-sm"
			/>
			&nbsp;
			{data.wind_direction !== null && isValid && (
				<div className="my-1 flex items-center justify-center self-end">
					<MoveUpIcon
						className="mr-1 size-4 rotate-45 rounded-full border border-white/80 bg-black/30 stroke-2 p-0.5 md:size-5"
						style={{
							transform: `rotate(${data.wind_direction}deg)`,
						}}
					/>
					<span className="self-end text-sm font-medium md:text-base">
						{getWindDirection(data.wind_direction)}
					</span>
				</div>
			)}
		</div>
	);
}

function LiveWeatherStatus({ dataUpdatedAt }: { dataUpdatedAt: number }) {
	const lastUpdatedAt = useCountdownLoop(dataUpdatedAt, 5);

	return (
		/* TODO: Handle faded auto scroll in long string */
		<span className="text-xs font-light text-gray-300">{`Last updated ${lastUpdatedAt}s ago`}</span>
	);
}
