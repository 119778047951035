import { useEffect, useState } from 'react';

export function useCountdownLoop(initialTime: number, throttleValue: number) {
	const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(Math.floor(Date.now() / 1000));
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		setCurrentTime(Math.floor(Date.now() / 1000));
	}, [initialTime]);

	const timeElapsed = Math.floor((currentTime * 1000 - initialTime) / 1000);
	const multipleTimeElapsed = Math.floor(timeElapsed / throttleValue) * throttleValue;

	return multipleTimeElapsed > 0 ? multipleTimeElapsed : 0;
}
