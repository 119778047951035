'use client';

import 'leaflet/dist/leaflet.css';
import { GeoJSON, MapContainer, TileLayer, TileLayerProps } from 'react-leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';

import { useIsMobile } from '@/hooks/useIsMobile';
import { lexendFont, weatherDecodeLocation } from '@/lib/utils';
import { useAppStore } from '@/lib/store';
import useIntersection from '@/hooks/useIntersection';
import { useTracker } from '@/lib/tracking/useTracker';
import { useCityWeatherData } from '@/api/weather';
import { getBrowserPermissions } from '@/lib/tracking/utils/browser-permissions';

import { MapLiveWeather } from './MapLiveWeather';
import { MapBottomControls } from './MapBottomControls';
import { MapSideControls } from './MapSideControl';
import { MapLiveGeoLocation } from './MapLiveGeoLocation';
import { MapMarkerOverlay } from './MapMarkerOverlay';
import { MapBottomCallouts } from './MapBottomCallouts';
import IndiaPolyline from './india-detailed-boundary_936.json';
import { MapErrorScreen } from './MapErrorScreen';
import { useMapConfig } from './useMapConfig';
import { ClientErrorBoundary } from '../ClientErrorBoundary';

const tileConfig: TileLayerProps = {
	url: 'https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=1RQew6LJVhOgUn53MMD8',
	// url: 'https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}',
	// url: 'http://www.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}'
};

interface MapContainerComponentProps {
	children: React.ReactNode;
}

export function MapSectionDesktop({ children }: MapContainerComponentProps) {
	const mapConfig = useMapConfig();

	return (
		<div className="relative flex flex-col items-center gap-2 md:container">
			<MapLiveWeather />
			<MapSideControls />
			<MapContainer
				{...mapConfig}
				className={`h-[40rem] w-full rounded-xl border border-gray-300 ring-offset-background focus-visible:outline-none focus-visible:ring-0 ${lexendFont.className}`}
				id="map-container__desktop"
				style={{
					fontFamily: lexendFont.style.fontFamily,
				}}
			>
				{children}
			</MapContainer>
		</div>
	);
}

export function MapSectionMobile({ children }: MapContainerComponentProps) {
	const mapConfig = useMapConfig();

	return (
		<>
			<MapLiveWeather />
			<div
				className="z-10 -my-6 size-full shadow-lg sm:z-0"
				style={{
					maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 1) 33rem, rgba(0, 0, 0, 0))',
				}}
			>
				<div
					className="z-10 size-full shadow-lg sm:z-0"
					style={{
						maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 33rem, rgba(0, 0, 0, 0))',
					}}
				>
					<div className="relative flex flex-col items-center gap-2">
						<MapSideControls />
						<MapContainer
							{...mapConfig}
							id="map-container__mobile"
							className={`h-[36rem] w-full ${lexendFont.className}`}
							style={{
								fontFamily: lexendFont.style.fontFamily,
							}}
						>
							{children}
						</MapContainer>
					</div>
				</div>
			</div>
		</>
	);
}

export default function MapSection() {
	const isMobile = useIsMobile();
	const Component = isMobile ? MapSectionMobile : MapSectionDesktop;
	const setMapLiveLocationEnabled = useAppStore(state => state.setMapLiveLocationEnabled);

	const [mapInView, setMapInView] = useState(false);
	const selectedCity = useAppStore(state => state.locationSelectedCity);
	const { isLoading: isMapDataLoading } = useCityWeatherData(selectedCity);

	const intersectionRef = useRef(null);
	const observer = useIntersection(intersectionRef, { threshold: 0.5 });
	const pathname = usePathname();
	const { isValid } = weatherDecodeLocation(pathname);

	useEffect(() => {
		if (isMapDataLoading) return;

		/**
		 * DEV: Resolving geolocation sometimes take a lot of time,
		 * to reduce this we let the browser load geolocation on hero section itself
		 * &&
		 * This is prevented if it is a city page
		 **/
		if (!mapInView && !isValid) {
			getBrowserPermissions().then(data => {
				if (data.geolocation === 'granted') {
					setMapLiveLocationEnabled('loading');
					setMapInView(true);
				}
			});
		}
	}, [isMapDataLoading]);

	useEffect(() => {
		/**
		 * DEV: Skip if not first load, map is not in view and if map data is still loading
		 **/
		if (mapInView || !observer?.isIntersecting || isMapDataLoading) return;

		/**
		 * DEV: For city pages we skip
		 **/
		if (isValid) return;

		setMapLiveLocationEnabled('loading');
		setMapInView(true);
	}, [observer?.isIntersecting, isMapDataLoading]);

	return (
		<ClientErrorBoundary fallback={<MapErrorScreen />}>
			<div ref={intersectionRef} id="map-container">
				<Component>
					<MapBottomControls />
					<TileLayer {...tileConfig} />
					<MapMarkerOverlay />
					<MapLiveGeoLocation />
					<MapBottomCallouts />
					<GeoJSON
						// @ts-ignore
						data={IndiaPolyline}
						style={{
							fillColor: 'transparent',
							color: '#242528',
							weight: 2,
						}}
					/>
				</Component>
			</div>
		</ClientErrorBoundary>
	);
}
