'use client';

import React, { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import Image from 'next/image';

import { Carousel, CarouselContent, CarouselItem } from '@/ui/carousel';
import RightArrowIconImage from '@/icons/right-arrow-icobn.svg';

import { weatherWorkingData } from './data';

const WeatherWorkingMobileList = () => {
	const plugin = useRef(Autoplay({ delay: 3000 }));
	return (
		<div className="mt-8 sm:block md:hidden">
			<Carousel
				opts={{
					loop: true,
					align: 'center',
				}}
				plugins={[plugin.current]}
				onMouseEnter={() => plugin.current.stop()}
				onMouseLeave={() => plugin.current.play()}
				className="w-full animate-in fade-in-0 slide-in-from-top-60 md:hidden md:max-w-md xl:block xl:max-w-md"
			>
				<CarouselContent>
					{weatherWorkingData.map((data, index) => {
						return (
							<CarouselItem key={index}>
								<div className="flex flex-col items-center md:gap-4">
									<Image src={data.image} alt={data.title} className="m-0" height={200} width={200} />
									<h2 className="text-center text-base font-normal text-secondary md:text-sm">
										{data.title}
									</h2>
								</div>
								{index !== weatherWorkingData.length - 1 && (
									<Image
										src={RightArrowIconImage}
										width={36}
										className="hidden h-auto md:block"
										alt="Right Arrow"
									/>
								)}
							</CarouselItem>
						);
					})}
				</CarouselContent>
			</Carousel>
		</div>
	);
};

export default WeatherWorkingMobileList;
