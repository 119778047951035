import { AlertTriangleIcon } from 'lucide-react';

import { generateRange } from '@/lib/round';
import { WeatherModes } from '@/types/constants';

import { LineChart } from './Charts/Line';
import { METRIC_CONSTANTS } from '../WeatherMap/utils';
import YAxis from './YAxis';

interface ErrorComponentOverlayProps {
	data: GraphDataProps[];
	selectedMode: WeatherModes | null;
}

export function ErrorComponentOverlay({ data, selectedMode }: ErrorComponentOverlayProps) {
	const GRAPH_Y_GAP = 10;
	const min = 0;
	const max = 40;
	const gridYValues = generateRange(min, max, GRAPH_Y_GAP);

	return (
		<LineChart
			metric={METRIC_CONSTANTS[selectedMode as WeatherModes]}
			yScale={{ type: 'linear', min: 0, max: max }}
			data={[{ data: data, id: 'temperature' }]}
			axisBottom={{
				renderTick: YAxis,
			}}
			theme={{
				grid: {
					line: {
						stroke: 'gray',
						opacity: 0.1,
					},
				},
				text: {
					fill: '#9ca3af',
					fontSize: '12px',
				},
			}}
			layers={['areas', ErrorOverlayMessage, 'axes', 'grid']}
			gridYValues={gridYValues}
		/>
	);
}

const ErrorOverlayMessage = () => {
	return (
		<svg
			width="90%"
			height="50%"
			// viewBox="0 0 200 100" // Adjust the viewBox as needed
			xmlns="http://www.w3.org/2000/svg"
			style={{ display: 'block' }} // Make SVG behave like a block element
		>
			<foreignObject x="0" y="0" width="100%" height="100%">
				<div className="mx-8 flex h-full flex-col items-center justify-center text-center md:mx-16">
					<AlertTriangleIcon size={28} className="fill-red-500 stroke-white" />
					<h1 className="text-xs font-medium md:text-base">
						Sorry data temporarily unavailable, will be back soon
					</h1>
				</div>
			</foreignObject>
		</svg>
	);
};
