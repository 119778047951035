import { WeatherModes } from '@/types/constants';

import { getFallbackValue } from './utils';

interface MapMarkerIconProps {
	control: MetriControl;
	isDeviceDowntime: boolean;
	className?: string;
}

export function LiveWeatherParser({ control, isDeviceDowntime, className }: MapMarkerIconProps) {
	const { isValid, value } = getFallbackValue(control.id as WeatherModes, control.value, isDeviceDowntime);

	return (
		<>
			{isValid ? (
				<>
					<span className="text-base md:text-lg">{control.value}</span>
					<span className={className}>
						{control.unitPrefix}
						{control.unit}
					</span>
				</>
			) : (
				value?.toString()
			)}
		</>
	);
}

export function MapTooltipParser({ control, isDeviceDowntime, className }: MapMarkerIconProps) {
	const { isValid, value } = getFallbackValue(control.id as WeatherModes, control.value, isDeviceDowntime);

	return (
		<span className="ml-2 text-xs md:ml-4 md:text-sm">
			{isValid ? <>{`${control.value}${control.unitPrefix}${control.unit}`}</> : value?.toString()}
		</span>
	);
}
