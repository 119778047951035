import { cva } from 'class-variance-authority';
import { RadioTowerIcon } from 'lucide-react';

import { Badge } from '@/ui/badge';
import { lexendFont } from '@/lib/utils';

import { IconBadgeProps } from './utils';
import { DownIcon } from './DownIcon';

enum WindModes {
	UNKNOWN = 'UNKNOWN',
	LIGHT = 'LIGHT',
	MODERATE = 'MODERATE',
	STRONG = 'STRONG',
}

function getWindBadgeConfig(wind: number) {
	switch (wind) {
		case 1:
			return {
				mode: WindModes.LIGHT,
				displayValue: 'Light breeze',
			};
		case 2:
			return {
				mode: WindModes.MODERATE,
				displayValue: 'Moderate breeze',
			};
		case 3:
			return {
				mode: WindModes.STRONG,
				displayValue: 'Strong breeze',
			};
		default:
			return {
				mode: WindModes.UNKNOWN,
				displayValue: 'Unknown',
			};
	}
}

const windTwVairants = cva(
	`whitespace-nowrap rounded-full border border-white py-1 text-xs font-light text-white shadow-md ${lexendFont.className}`,
	{
		variants: {
			wind: {
				LIGHT: 'bg-[#DBE8FF] text-[#0E3272] hover:bg-[#DBE8FF]',
				MODERATE: 'bg-[#9CC0FF] text-[#0E3272] hover:bg-[#9CC0FF]',
				STRONG: 'bg-[#538CEE] text-[#FFFFFF] hover:bg-[#538CEE]',
				UNKNOWN: 'hidden',
			},
		},
	},
);

export function WindIcon({ value, props, showUnavailable }: IconBadgeProps) {
	if (!value) {
		return <DownIcon props={props} showUnavailable={showUnavailable} value={value} />;
	}

	const modeValue = getWindBadgeConfig(value);

	return (
		<Badge className={windTwVairants({ wind: modeValue.mode })} {...props}>
			<RadioTowerIcon size={16} className="mr-1" strokeWidth={1.5} />
			{modeValue.displayValue}
		</Badge>
	);
}
