import { Dispatch, SetStateAction, useRef } from 'react';
import { Check, ChevronDownIcon } from 'lucide-react';
import { Popover, PopoverButton } from '@headlessui/react';

import { Button } from '@/ui/button';
import { cn } from '@/lib/utils';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/ui/command';
import { useAppStore } from '@/lib/store';
import { PopoverPanel } from '@/ui/popover';

interface LocalitySelectionHolderProps {
	selectedCity: string | undefined;
	setSelectedCity: Dispatch<SetStateAction<string | undefined>> | ((cityId: string) => void);
	selectedLocality: string | undefined;
	setSelectedLocality: Dispatch<SetStateAction<string | undefined>> | ((localityId: string) => void);
	className?: string;
}

const CITY_SELECTOR = {
	PLACEHOLDER: 'Select your city',
	404: 'Not available in this city',
};

const LOCALITY_SELECTOR = {
	PLACEHOLDER: 'Select your area',
	404: 'Not available in this locality',
};

const CITY_GROUPS = [
	{
		TITLE: 'Popular Cities',
		selector: (weatherData: ParsedWeatherData) =>
			Object.values(weatherData.data)
				.filter(city => city.is_top)
				.sort((a, b) => a.city_name.localeCompare(b.city_name)),
	},
	{
		TITLE: 'Other Cities',
		selector: (weatherData: ParsedWeatherData) =>
			Object.values(weatherData.data)
				.filter(city => !city.is_top)
				.sort((a, b) => a.city_name.localeCompare(b.city_name)),
	},
];

export function LocalitySelectionHolder({
	selectedCity,
	setSelectedCity,
	selectedLocality,
	setSelectedLocality,
	className,
}: LocalitySelectionHolderProps) {
	const parsedWeatherData = useAppStore(state => state.parsedWeatherData);
	const developerOptions = useAppStore(state => state.developerOptions);

	const citySelectRef = useRef<HTMLButtonElement>(null);
	const locationSelectRef = useRef<HTMLButtonElement>(null);

	const selectedCityLocalityData =
		selectedCity && parsedWeatherData
			? Object.values(parsedWeatherData.data[selectedCity].localities).sort((a, b) =>
					a.locality_name.localeCompare(b.locality_name),
				)
			: null;

	const isCitySelectorDisabled = !parsedWeatherData;
	const isLocalitySelectorDisabled = !selectedCity || !parsedWeatherData;

	return (
		<>
			{/* DEV: Here modal mode is important as we are opening a Portal in a Dialog so scrolling doesn't block */}
			<Popover>
				<PopoverButton
					aria-label={CITY_SELECTOR.PLACEHOLDER}
					aria-expanded={citySelectRef.current?.dataset.open}
					className={cn(
						'group w-full justify-between overflow-hidden rounded-lg shadow-sm focus-visible:ring-0 md:w-72',
						className,
					)}
					as={Button}
					role="combobox"
					disabled={isCitySelectorDisabled}
					variant="outline"
					ref={citySelectRef}
				>
					{selectedCity && parsedWeatherData
						? parsedWeatherData.data[selectedCity].city_name
						: CITY_SELECTOR.PLACEHOLDER}
					<ChevronDownIcon className="ml-2 mr-1 size-4 shrink-0 text-gray-500 transition group-data-[open]:rotate-180" />
				</PopoverButton>
				<PopoverPanel
					modal={true}
					anchor={{ gap: '4px' }}
					className="flex w-[var(--button-width)] flex-col overflow-hidden"
					transition
				>
					{({ close }) => (
						<Command>
							<CommandInput placeholder={CITY_SELECTOR.PLACEHOLDER} disabled={isCitySelectorDisabled} />
							<CommandList>
								<CommandEmpty className="mx-8 my-4 text-center text-sm text-muted-foreground">
									{CITY_SELECTOR[404]}
								</CommandEmpty>
								{parsedWeatherData &&
									CITY_GROUPS.map((group, index) => {
										const cities = group.selector(parsedWeatherData);
										return (
											<CommandGroup key={index} heading={group.TITLE} className="ml-1">
												{cities.map(city => (
													<CommandItem
														className={cn('cursor-pointer font-light', {
															'font-normal text-gray-800': selectedCity === city.city_id,
														})}
														key={city.city_id}
														value={city.city_name}
														onSelect={() => {
															setSelectedCity(city.city_id);
															close();
														}}
													>
														<span>{city.city_name}</span>
														{selectedCity === city.city_id && (
															<Check className="ml-2 size-4" />
														)}
														{developerOptions.showDeviceCount && (
															<span className="ml-auto self-end text-gray-500">
																({Object.keys(city.localities).length})
															</span>
														)}
													</CommandItem>
												))}
											</CommandGroup>
										);
									})}
							</CommandList>
						</Command>
					)}
				</PopoverPanel>
			</Popover>
			<Popover>
				<PopoverButton
					className={cn(
						'group w-full justify-between overflow-hidden rounded-lg shadow-sm focus-visible:ring-0 md:w-72',
						className,
					)}
					aria-label={LOCALITY_SELECTOR.PLACEHOLDER}
					aria-expanded={locationSelectRef.current?.dataset.open}
					as={Button}
					disabled={isLocalitySelectorDisabled}
					variant="outline"
					ref={locationSelectRef}
					role="combobox"
				>
					<span className="overflow-hidden text-ellipsis md:max-w-48">
						{parsedWeatherData &&
						selectedLocality &&
						selectedCity &&
						parsedWeatherData.data[selectedCity] &&
						parsedWeatherData.data[selectedCity].localities[selectedLocality]
							? parsedWeatherData.data[selectedCity].localities[selectedLocality].locality_name
							: LOCALITY_SELECTOR.PLACEHOLDER}
					</span>
					<ChevronDownIcon className="ml-2 mr-1 size-4 shrink-0 text-gray-500 transition group-data-[open]:rotate-180" />
				</PopoverButton>
				<PopoverPanel
					modal={true}
					anchor={{ gap: '4px' }}
					className="flex w-[var(--button-width)] flex-col overflow-hidden"
					transition
				>
					{({ close }) => (
						<Command>
							<CommandInput
								placeholder={LOCALITY_SELECTOR.PLACEHOLDER}
								disabled={isLocalitySelectorDisabled}
							/>
							<CommandList>
								<CommandEmpty className="mx-8 my-4 text-center text-sm text-muted-foreground">
									{LOCALITY_SELECTOR[404]}
								</CommandEmpty>
								<CommandGroup className="">
									{selectedCityLocalityData &&
										selectedCityLocalityData.map(locality => (
											<CommandItem
												className={cn('ml-2 cursor-pointer font-light', {
													'font-normal text-gray-800':
														selectedLocality === locality.locality_id,
												})}
												key={locality.locality_id}
												value={locality.locality_name}
												onSelect={() => {
													setSelectedLocality(locality.locality_id);
													close();
												}}
											>
												<span className="">{locality.locality_name}</span>
												{selectedLocality === locality.locality_id && (
													<Check className="ml-2 size-4" />
												)}
											</CommandItem>
										))}
								</CommandGroup>
							</CommandList>
						</Command>
					)}
				</PopoverPanel>
			</Popover>
		</>
	);
}
