import L from 'leaflet';
import { MapContainerProps, useMap } from 'react-leaflet';

import { useAppStore } from '@/lib/store';
import { useIsMobile } from '@/hooks/useIsMobile';

export function useMapConfig() {
	const developerOptions = useAppStore(state => state.developerOptions);
	const isMobile = useIsMobile();

	const mapConfig: MapContainerProps = {
		fadeAnimation: true,
		zoomAnimation: true,
		maxZoom: 14,
		minZoom: 4,
		maxBounds: isMobile
			? new L.LatLngBounds(new L.LatLng(-4, 98), new L.LatLng(45, 62.5))
			: new L.LatLngBounds(
					new L.LatLng(-2.54457732085584, 100.88476562500001),
					new L.LatLng(35.61598581915534, 70.02734375000001),
				),
		attributionControl: false,
		zoomControl: false,
	};

	return mapConfig;
}
