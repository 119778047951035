'use client';

import { useEffect } from 'react';

import { useAppStore } from '@/lib/store';

export function RootAppData({ parsedWeatherData }: { parsedWeatherData: ParsedWeatherData }) {
	const setParsedWeatherData = useAppStore(state => state.setParsedWeatherData);

	useEffect(() => {
		if (parsedWeatherData) {
			setParsedWeatherData(parsedWeatherData);
		}
	}, [parsedWeatherData, setParsedWeatherData]);

	return null;
}
